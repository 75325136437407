/*========================================
 u-external
========================================*/

/*== swiper ====================*/
.slider {
  overflow: hidden;
  @include breakPoint(xs) {
    width: 100%;
    padding: 0 15px;
    margin-top: 40px;
  }
  .swiper-container {
    padding-bottom: 20px;
    width: 100%;
    max-width: 567px;
    margin: 0 auto;
    overflow: visible;
    .swiper-wrapper {
      .swiper-slide {
        img {
          display: block;
          width: 100%;
        }
      }
    }
    .swiper-pagination {
      display: none;
      bottom: 0;
      @include breakPoint(xs) {
        display: block;
      }
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        // margin: 0 6px;
        background-color: $color_white;
        border: 1px solid $color_gray04;
        opacity: 1;
      }
      .swiper-pagination-bullet-active {
        background-color: $color_gray01;
        border: 1px solid $color_gray01;
      }
    }
  }
}

.featureSlider {
  overflow: hidden;
  @include breakPoint(xs) {
    width: 100%;
    padding: 0 15px;
    margin-top: 40px;
  }
  .swiper-container {
    // padding-bottom: 20px;
    // width: 80%;
    // margin: 0 auto;
    // overflow: visible;
    .swiper-wrapper {
      .swiper-slide {

        img {
          display: block;
          width: 85%;
          margin: 0 auto;
        }
      }
    }
  }
  .swiper-button-next {
    background-image: none;
    font-size: 38px;
    font-weight: bold;
    color: $color_gray01;
  }
  .swiper-button-prev {
    background-image: none;
    font-size: 38px;
    font-weight: bold;
    color: $color_gray01;
  }
  .swiper-pagination {
    display: block;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: $color_white;
      border: 1px solid $color_gray04;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: $color_gray01;
      border: 1px solid $color_gray01;
    }
  }
}

.postSwiper {
  overflow-x: hidden;
  @include breakPoint(xs) {
    width: 100%;
    padding: 0 15px;
    margin-top: 40px;
  }
  .swiper-wrapper {
    .swiper-slide {
      width: 570px !important;
      margin-bottom: 36px;
      @include breakPoint(xs) {
        width: 100vw !important;
        padding: 0 15px;
      }
    }
  }
  .swiper-pagination {
    position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background-color: #ffffff;
      border: 1px solid #E0E0E0;
      opacity: 1;
      position: relative;
      display: inline-block;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      border-radius: 0;
    }
    .swiper-pagination-bullet-active {
      background-color: $color_gray01;
      border: 1px solid $color_gray01;
    }
  }
}
