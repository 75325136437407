.p-footer-content {
  width: 1126px;
  height: 193px;
  margin: 0 auto;

  @include breakPoint(sm) {
    width: 332px;
    height: auto;
  }

  &__top {
    height: 96px;
    color: white;

    @include breakPoint(sm) {
      flex-wrap: wrap;
    }

    &--experience {
      width: 432px;
      height: 100%;
    }
    &--category {
      width: 484px;
      height: 100%;

      @include breakPoint(sm) {
        display: none;
      }
    }
  }
  &__logo-wrap {
    width: 207px;
    height: 100%;

    @include breakPoint(sm) {
      display: none;
    }
  }
  &__logo {
    width: 130px;
    height: 44px;
    background-image: url('images/flactive/footer_logo.png');
  }
  &__bottom {
    justify-content: space-between;
    height: 55px;
    line-height: 55px;
    border-top: 2px solid rgba(255,255,255,0.5);
    margin-top: 40px;

    @include breakPoint(sm) {
      margin-top: 32px;
      flex-wrap: wrap;
      border: none;
      height: auto;
    }

    &--left {
      color: #fff;
    }
    &--right {
      width: 233px;
      height: 100%;
      font-size: 10px;
      text-align: right;
      color: #fff;
      font-family: serif;
      font-weight: 100;

      @include breakPoint(sm) {
        width: 100%;
        text-align: center;
        font-size: 12px;
        margin-top: 40px;
      }
    }
  }
}
