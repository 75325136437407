.p-search-sidetab-title-wrap {
  padding-top: 110px;
}
.p-search-sidetab-form {
  min-width: 300px;
  width: 85%;
  margin: 30px auto 0;
  font-family: serif;

  @include breakPoint(sm) {
    width: 400px;
    max-width: 95%;
  }
}
.p-search-sidetab-form-pulldown {
  position: relative;
  width: 25%;

  &::after {
    content: "";
    position: absolute;
    right: 20px;
    top: 24px;
    width: 8px;
    height: 8px;
    border-top: 2px solid black;
    border-left: 2px solid black;
    transform: translateY(-50%) rotate(-135deg);
    pointer-events: none;
  }
  &__select {
    width: 100%;
    height: 50px;
    padding: 0 15%;
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @include breakPoint(sm) {
      width: 100%;
      height: 50px;
      padding: 0 13px;
      font-size: 14px;
    }
  }
}
.p-search-sidetab-form-field-and-btn {
  width: 75%;
}
.p-search-sidetab-form-input-wrap {
  padding: 0 8px;
  width: calc(100% - 50px);

  @include breakPoint(sm) {
    width: calc(100% - 25px);
    padding-right: 0;
  }
}
.p-search-sidetab-form-input {
  width: 100%;
  height: 50px;
  font-size: 10px;
}
.p-search-sidetab-form-submit {
  display: inline;
  width: 50px;
  height: 50px;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  background-image: url('images/flactive/header_search_icon.png');
}
.p-search-sidetab-keyword-list {
  width: 350px;
  max-width: 95%;
  margin: 30px auto 0;
  gap: 12px 15px;
}
.p-search-sidetab-keyword-wrap {
  background-color: white;
  height: 34px;
  line-height: 34px;
  border: 1px solid black;
}
.p-search-sidetab-keyword {
  font-size: 16px;
  text-align: left;
  color: #000;
  padding: 0 15px;
  font-weight: 500;
}
.p-search-sidetab-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0,0,0,0.5);
}
