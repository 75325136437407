/*========================================
 expert
========================================*/
.expert-wrap {
  padding: 2% 3% 2%;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
}
.expert-profile-wrap {
  position: relative;
}
.expert-ttl-block {
  margin-bottom: 1.0em;
  text-align: left;
}

.expert-ttl {
  font-weight: bold;
  font-size: 2.0rem;
  font-family: "FuturaOblique",sans-serif;
}

.expert-subttl {
  font-size: 1.5rem;
  margin-left: 1.0rem;
}

.expert-avatar-wrap {
  font-family: "helvetica", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", sans-serif;
  float: left;
  margin: 0 1.0em 0.5em 0;
}
.expert-avatar {
  border-radius: 50%;
}
.expert-name-wrap {
  height: 50px;
  p {
    height: 25px;
    line-height: 25px;
  }
}
.expert-detail-profile {
  font-size: 1.2rem;
  clear: both;
  line-height: 1.5;
}
.expert-position {
  font-size: 1.1rem;
  color: gray;
}
.expert-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5em;
}
