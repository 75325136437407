/*========================================
 breadcrumbs
========================================*/

.c-breadcrumbs {
  display: flex;
  margin: 0 auto 20px;
  width: 960px;
  @include breakPoint(md) {
    width: 100%;
    padding: 0 20px;
    margin: 15px auto 0;
  }
  @include breakPoint(sm) {
    width: 100%;
    // padding: 0 15px;
    padding: 0;
    // margin: 300px auto 0;
    margin-left: 0;
    margin-bottom: 20px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  li {
    align-items: center;
    display: flex;
    font-size: 12px;
    line-height: 0;
    text-decoration: none;
  }
  li.active {
    padding: 0 15px 0 0;
  }

  a span {
    line-height: 0;
    text-decoration: none;
  }

  &__separator {
    color: #999;
    margin: 0 5px;
  }
}

.c-breadcrumbs::-webkit-scrollbar {
  display: none;
}
