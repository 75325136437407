.htmlTableContent {
  position: relative;
  display: block;
  padding-bottom: 40px;
  font-size: 14px;
  white-space: nowrap;
  overflow: auto;

  &__scrollable_disable {
    width: 100%;
    table-layout: auto;

    td {
      p {
        white-space: normal;
      }
    }
  }

  &__scrollable_enable {
    width: auto;
  }

  table,
  th,
  td {
    border: 1px solid #aaa;
  }

  table {
    overflow-x: auto;
    border-spacing: 0;
  }

  tbody {
    white-space: nowrap;
  }

  th,
  td {
    padding: 1px;
    border-top-width: 0;
    border-left-width: 0;
    vertical-align: middle;

    @media screen and (max-width: 640px) {
      font-size: 14px !important;
    }

    strong, em, s {
      color: inherit;
    }

    em {
      font-style: italic;
    }

    strong {
      font-weight: bold;
    }

    *::before, *::after {
      color: inherit;
    }

    .cellContent {

      & > p {
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  th:last-child,
  td:last-child {
    border-right-width: 0;
  }

  tr:last-child td {
    border-bottom-width: 0;
  }
}

