.c-section-title {
  &__en-wrap {
    height: 40px;

    @include breakPoint(sm) {
      height: 29px;
    }
  }
  &__en {
    line-height: 1.0;
    font-size: 40px;
    font-weight: 500;
    font-family: "Marcellus";

    @include breakPoint(sm) {
      font-size: 37px;
    }
  }
  &__ja-wrap {
    text-align: center;
    margin-top: 9px;

    @include breakPoint(sm) {
      padding-top: 8px;
    }
  }
  &__ja {
    line-height: 1.0;
    font-size: 13px;
    font-weight: 500;
    font-family: YuGothic, 'Yu Gothic', sans-serif;
  }
}
