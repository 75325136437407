/*========================================
 description
========================================*/

.p-description {
  padding: 20px;
  border: 1px dashed $color_gray02;
  &__text {
    line-height: 1.5;
    font-size: 1.4rem;
  }
}
