/*==================================
 mediaPage
==================================*/

.p-mediaPage {
  p {
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.4rem;
  }

  h2 {
    position: relative;
    padding: 10px;
    margin-bottom: 30px;
    line-height: 1.5;
    font-size: 1.9rem;
    font-weight: bold;
    border: 1px solid $color_base;

  }
  
  h3 {
    padding: 6px 0 12px 20px;
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.8rem;
    font-weight: bold;
    border-left: 3px solid $color_base;
    border-bottom: 1px dotted $color_base;
  }
  
  h4 {
    padding: 0 0 12px 0;
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.4rem;
    font-weight: bold;
    border-bottom: 1px solid $color_base;
  }

  ul {
    list-style: circle;

    li {
      margin-left: 20px;
    }
  }
  ol {
    list-style: decimal;

    li {
      margin-left: 20px;
    }
  }
}
