/*========================================
 base
========================================*/

*,
*::before,
*::after {
  box-sizing: border-box;
  color: $color_base;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "helvetica", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", sans-serif;
  @media all and ( -ms-high-contrast: none){
    font-family: "Meiryo", "Segoe UI", sans-serif;
  }
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and ( -webkit-min-device-pixel-ratio: 2),( min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

a {
  color: $color_base;
  text-decoration: none;
  transition: all .15s ease;
}

a:hover {
  color: $color_base;
}

.markdown-hyperlink {
  color: #23A7FA;
}
.markdown-hyperlink:hover {
  color: #23A7FA;
  text-decoration: underline;
}

strong {
  font-weight: bold;
}
