.p-footer-list-item {
  &__experience {
    width: 152px;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
    margin-left: 30px;
    margin-right: 10px;
    margin-bottom: 20px;
    color: white;
    font-weight: 700;
    font-family: sans-serif;

    @include breakPoint(sm) {
      width: 145px;
      margin-left: 20px;
      margin-right: 0;
      font-size: 19px;
    }

    &--en {
      font-weight: 100;
      color: rgba(255,255,255,0.5);
      font-family: "Marcellus";
      font-weight: normal;
    }
  }
  &__category {
    width: 84px;
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    margin: 7px 11px 10px 25px;
    color: white;
    font-family: sans-serif;
    font-weight: bold;
  }
}
