.p-feature-top-content {
  position: absolute;

  &__background-image {
    width: 100%;
    height: 471px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('images/thumb_05.png');
    filter: blur(12px) grayscale(60%);

    &--first {
      background-image: url('images/thumb_02.png');
    }
    &--secound {
      // background-image: url('media/cheerful/eyecatch/02.png');
    }
    &--third {
      // background-image: url('media/cheerful/eyecatch/03.png');
    }
    &--fourth {
      background-image: url('images/thumb_05.png');
    }

    @include breakPoint(sm) {
      height: 522px;
      filter: blur(12px) grayscale(60%);
      background-position: center;
    }
  }
  &__section-title-wrap {
    top: 76px;
    left: 122px;

    @include breakPoint(sm) {
      width: 106px;
      height: 65px;
      top: 68px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &__section-title {
    &--en {
      font-family: "Marcellus" !important;
      font-size: 40px;
      font-weight: normal;
      color: #fff;
      font-weight: 500;
      font-family: serif;

      @include breakPoint(sm) {
        height: 24px;
        font-size: 28px;
      }
    }

    &--ja {
      font-family: YuMin;
      font-size: 13px;
      font-weight: 500;
      color: #fff;
      letter-spacing: 5px;
      margin-top: 15px;
      font-weight: 600;
      font-family: serif;

      @include breakPoint(sm) {
        width: 30px;
        height: 14px;
        margin: 12px auto 0;
        font-size: 10px;
      }
    }
  }
  &__title {
    top: 198px;
    left: 124px;
    font-size: 62px;
    font-weight: normal;
    color: #fff;
    font-family: "Marcellus";

    @include breakPoint(sm) {
      width: 239px;
      height: 32px;
      top: 405px;
      left: 22px;
      font-size: 29px;
    }
  }
  &__subtitle {
    top: 271px;
    left: 124px;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    font-family: YuMincho, 'Yu Mincho', serif;

    @include breakPoint(sm) {
      width: 133px;
      height: 14px;
      top: 448px;
      left: 22px;
      font-size: 12px;
    }
  }
  &__image {
    top: 91px;
    right: 160px;
    width: 500px;
    height: 282px;
    background-repeat: no-repeat;
    background-size: cover;

    &--first {
      background-image: url('images/thumb_02.png');
    }
    &--secound {
      // background-image: url('media/cheerful/eyecatch/02.png');
    }
    &--third {
      // background-image: url('media/cheerful/eyecatch/03.png');
    }
    &--fourth {
      background-image: url('images/thumb_05.png');
    }

    @include breakPoint(sm) {
      width: 100%;
      height: 238px;
      top: 140px;
      right: 0;
    }
  }
}
