.p-top-description {
  font-size: 0;
  font-family: serif;

  &__content {
    height: 729px;

    &--left {
      width: 45%;
      background-color: white;
      position: relative;

      @include breakPoint(sm) {
        width: 0;
        position: static;
      }
    }
    &--right {
      width: 55%;
      background-color: #f7f7f7;

      @include breakPoint(sm) {
        width: 100%;
        height: 573px;
      }
    }
  }

  &__concept-wrap {
    height: 729px;

    &--left {
      height: 142px;
      padding-top: 15px;
      position: absolute;
      top: 160px;
      right: 102px;

      @include breakPoint(sm) {
        width: 242px;
        height: 76px;
        top: 86px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    &--right {
      width: 408px;
      height: 142px;
      margin: 160px 0 0 105px;

      @include breakPoint(sm) {
        width: 296px;
        height: 100px;
        margin: 217px auto 0;
        text-align: center;
      }
    }
  }

  &__concept {
    &--left {
    font-weight: bold;
      height: 111px;
    }
    &--right {
    font-weight: bold;
      font-size: 19px;
      line-height: 1.82;
      text-align: left;
      color: #000;

      @include breakPoint(sm) {
        line-height: 1.75;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
.p-top-description-concept-left {
  &__top {
    width: 300px;
    height: 33px;
    font-size: 30px;
    line-height: 1.29;
    text-align: left;
    color: #000;

    @include breakPoint(sm) {
      width: 242px;
      height: 27px;
      font-size: 24px;
      line-height: 27px;
      text-align: center;
    }
  }
  &__bottom {
    width: 100%;
    height: 55px;
    margin-top: 23px;
    background-repeat: no-repeat;
    background-image: url('images/flactive/about_logo.png');

    @include breakPoint(sm) {
      width: 98px;
      height: 33px;
      background-size: cover;
      margin: 16px auto 0;
    }
  }
}
.p-top-description-post-list {
  height: 530px;
  position: absolute;
  top: 462px;

  @include breakPoint(sm) {
    height: 387px;
    top: 417px;
  }
}
