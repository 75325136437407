/*========================================
 button
========================================*/

/*== base ====================*/
.c-button {
  display: inline-block;
  padding: 18px;
  line-height: 1.5;
  text-align: center;
  font-size: 1.4rem;
  color: $color_base;
  border: 1px solid $color_base;
}

/*== angle ====================*/
.c-button--angle {
  @extend .c-button;
  position: relative;
  padding-right: 30px;
  font-weight: bold;
  border-radius: 2px;
  &:hover {
    &::after {
      right: 8px;
    }
    @include breakPoint(md) {
      &::after {
        right: 12px;
      }
    }
  }
  &:active {
    opacity: .5;
  }
  &::after {
    content: "\f105";
    position: absolute;
    top: 17px;
    right: 12px;
    font-family: FontAwesome;
    font-size: 1.6rem;
    transition: .3s;
  }
}

/*== more ====================*/
.c-button--more {
  @extend .c-button;
  z-index: 2;
  position: relative;
  display: block;
  height: 56px;
  padding: 0;
  line-height: 56px;
  text-align: center;
  text-decoration: none;
  background-color: $color_white;
  outline: none;
  transition: .3s;
  &::before,
  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    display: block;
    transition: .3s;
  }
  &::after {
    top: 4px;
    left: 4px;
    width: 254px;
    height: 46px;
    border: 1px solid $color_base;
    opacity: 0;
  }
  &:hover::after {
    opacity: 1;
    @include breakPoint(md) {
      opacity: 0;
    }
  }
}

/*== color ====================*/
// amazon
.c-button--amazon {
  @extend .c-button--angle;
  color: $color_white;
  background-color: $color_amazon;
  border: 1px solid $color_amazon;
  &::after {
    color: $color_white;
  }
}
// rakuten
.c-button--rakuten {
  @extend .c-button--angle;
  color: $color_white;
  background-color: $color_rakuten;
  border: 1px solid $color_rakuten;
  &::after {
    color: $color_white;
  }
}
// zozotown
.c-button--zozotown {
  @extend .c-button--angle;
  color: $color_white;
  background-color: $color_base;
  border: 1px solid $color_base;
  &::after {
    color: $color_white;
  }
}
// product
.c-button--product {
  @extend .c-button--angle;
  color: $color_white;
  background-color: $color_product;
  border: 1px solid $color_product;
  &::after {
    color: $color_white;
  }
}
// entry
.c-button--entry {
  @extend .c-button--angle;
  color: $color_white;
  background-color: $color_entry;
  border: 1px solid $color_entry;
  &::after {
    color: $color_white;
  }
}
