.p-article-content {
  display: none;
  &--active {
    display: block;
  }
}
.article-policy {
  background: #f5f5f5;
  border: 1px solid #eee;
  color: #9a9a9a;
  display: block;
  font-size: 10px;
  line-height: 1.8;
  padding: 1em;
  margin-bottom: 10px;
  margin-top: 10px;
}
