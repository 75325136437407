.bannerContent {
  position: relative;
  display: block;

  &__box {
    display: block;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  &__bottom_label {
    padding: 10px;
    padding-bottom: 15px;
    text-align: center;
  }

  &__label {
    padding: 10px;
    padding-top: 15px;
    text-align: center;
  }
}
