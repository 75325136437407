.p-article-tab {
  width: 290px;
  height: 54px;
  text-align: center;

  @include breakPoint(sm) {
    width: 122px;
    height: 34px;
    text-align: center;
  }

  &--center {
    margin: 0 88px;

    @include breakPoint(sm) {
      margin: 0 11px;
    }
  }

  &__span {
    &--pc {
      font-size: 22px;
      line-height: 1.09;
      text-align: center;
      color: #000;
      font-family: YuGothic, 'Yu Gothic', sans-serif;
      font-weight: bold;

      @include breakPoint(sm) {
        display: none;
      }
    }

    &--sp {
      display: none;

      @include breakPoint(sm) {
        display: inline;
        font-size: 18px;
        line-height: 1.09;
        text-align: center;
        color: #000;
      }
    }
  }

  &--active {
    border-bottom: 2px solid black;
  }
}
