.p-singlePostMain {
  .p-buttonItem {
    p {
      margin-bottom: 0;
    }
  }
  span {
    // font-size: 1rem !important;
  }
  p {
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.4rem;
  }
  img {
    display: block;
    object-fit: cover;
    max-width: 100%;
    max-height: 350px;
    margin: 0 auto;
    @include breakPoint(xs) {
      max-width: 100%;
      margin: 0 auto;
    }
    &__credit {
      font-size: 1.3rem;
      margin: 5px 0 20px;
      width: 100%;
      a {
        color: $color_gray01;
      }
    }
  }
  .image-large {
    img {
      max-width: initial;
      width: auto !important;
      @include breakPoint(xs) {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
  .p-singleRelated__thumb {
    margin-right: 20px;
  }
  p:has(img) {
    margin-bottom: 0;
  }
}
.p-singlePostMain--wp {
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 1.4rem;
  h2 {
    position: relative;
    padding: 20px;
    margin-bottom: 30px;
    line-height: 1.5;
    font-size: 2.1rem;
    font-weight: bold;
    border: 2px solid $color_base;
    @include breakPoint(xs) {
      font-size: 2rem;
    }
    &::after {
      content: "";
      z-index: -1;
      position: absolute;
      top: 6px;
      left: 6px;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #F2F2EC;
    }
  }
  h3 {

    padding: 6px 0 12px 20px;
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.8rem;
    font-weight: bold;
    border-left: 3px solid $color_base;
    border-bottom: 1px dotted $color_base;
  }
  h4 {
    padding: 0 0 12px 0;
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.4rem;
    font-weight: bold;
    border-bottom: 1px solid $color_base;
  }
  p {

    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.4rem;
  }
  .wp-block-image {
    margin-bottom: 20px;
    .wp-element-caption {
      font-size: 1.3rem;
      margin: 5px 0 20px;
      width: 100%;
      a {
          color: #868686;
        }

    }
  }
  .wp-block-quote {
    padding: 33px 12px 24px 12px;
    margin-bottom: 20px;
    background-image: url("images/single/quote.png");
    background-repeat: no-repeat;
    // background-size: 50%;
    background-size: cover;
    p {
      line-height: 1.5;
      font-size: 1.3rem;
    }
    cite {
      line-height: 1.5;
      display: inline-block;
      width: 100%;
      text-align: right;
      font-size: 1.3rem;
      color: $color_gray01;
      &:before {
        color: #868686;
        content: "引用元 | ";
      }
    }
  }
  // .wp-block-quote {
  //   p {
  //     display: block;
  //     font-size: 1.6rem;
  //     line-height: 1.6;
  //     margin: 0 10px;
  //   }
  //   margin: 2em 0;
  //   padding: 0.5em 1.5em;
  //   display: block;
  //   position: relative;
  //   border-top: solid 2px #ccc;
  //   border-bottom: solid 2px #ccc;
  //   cite {
  //     display: block;
  //     margin: 0 10px;
  //     font-size: 1.2rem;
  //     text-align: right;
  //   }
  //   &:before {
  //     content: '';
  //     position: absolute;
  //     top: -10px;
  //     width: 2px;
  //     left: 1.5em;
  //     height: -webkit-calc(100% + 20px);
  //     height: calc(100% + 20px);
  //     background-color: #ccc;
  //   }
  //   &:after {
  //     content: '';
  //     position: absolute;
  //     top: -10px;
  //     right: 1.5em;
  //     width: 2px;
  //     height: -webkit-calc(100% + 20px);
  //     height: calc(100% + 20px);
  //     background-color: #ccc;
  //   }
  // }
  .wp-block-table {
    margin-bottom: 20px;
    table {
      border: 2px solid #e8e8e8;
      font-size: 1.3rem;
      thead {
        border-bottom: none;
        th {
          border: 2px solid #e8e8e8;
          background-color: #f7f7f7;
        }
      }
      td {
        border: 2px solid #e8e8e8;
        line-height: 1.5em;
        padding: 0.5em;
        word-wrap: break-word;
      }

    }
    .wp-element-caption {
      font-size: 1.3rem;
      margin: 5px 0 0;
      width: 100%;
      a {
          color: #868686;
      }
    }
  }
  .wp-block-my-blocks-table-title-block {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 10px;
    text-align: center;
  }
  ul {
    position: relative;
    width: 100%;
    padding: 40px;
    margin-bottom: 20px;
    border: 1px solid #E0E0E0;
    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 16px;
      line-height: 1.5;
      font-size: 1.4rem;
      &:before {
        position: absolute;
        content: "";
        top: 9px;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $color_base;
      }
    }
  }
  ol {
    position: relative;
    width: 100%;
    padding: 40px;
    margin-bottom: 20px;
    border: 1px solid #E0E0E0;
    counter-reset: number 0;
    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 16px;
      line-height: 1.5;
      font-size: 1.4rem;
      &:before {
        counter-increment: number;
        content: counter(number);
        top: 0;
        font-family: "FuturaOblique", sans-serif;
        font-size: 1.6rem;
        font-style: normal;
        left: 0;
        position: absolute;
      }
    }
  }
  @include breakPoint(xs) {
    .wp-embedded-content {
      max-width: 100%;
      overflow: scroll;
    }
  }
  table {
    white-space: nowrap;
    min-width: 100%;
  }
  .wp-block-flexible-table-block-table {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 20px;
  }
  .wp-block-table {
    width: 100%;
    overflow-x: scroll;
  }
  iframe {
    max-width: 100%;
    // max-height: 250px;
  }
}
