/*========================================
 container
========================================*/

.container {
  width: 1000px;
  margin: 0 auto;
  @include breakPoint(md) {
    width: 100%;
    padding: 0 15px;
  }
}
