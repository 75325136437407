.wpcf7 {
  padding: 0 200px;
  @include breakPoint(md) {
    padding: 0;
  }
  form.wpcf7-form {
    width: 100%;
  }
  .wpcf7-form-control-wrap {
    width: 100%;
    display: inline-block;
    input.wpcf7-form-control, textarea.wpcf7-form-control {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      &:focus {
        outline: none;
        border: 1px solid #333;
      }
      @include breakPoint(sm) {
        padding: 10px;
      }
    }
  }
  input[type="submit"].wpcf7-form-control {
    display: inline-block;
    width: 100%;
    border: solid 1px #000;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
  }
  form {
    .wpcf7-response-output {
      margin: 20px 0 !important;
      padding: 10px 5px !important;
      font-size: 2rem;
      border: 2px solid #0d6efd !important;
      background-color: #0d6efd11;
      color: #0d6efd;
    }
  }
  form.invalid, form.failed {
    .wpcf7-response-output {
      border: 2px solid #dc3232 !important;
      background-color: #dc323211;
      color: #dc3232;
    }
  }
}
