.u-w100 {
  width: 100%;
}
.u-flex {
  display: flex;
}
.u-flex-wrap {
  flex-wrap: wrap;
}
.u-relative {
  position: relative;
}
.u-margin0auto {
  margin: 0 auto;
}
.u-border-none {
  border: none;
}
.u-width-fit-content {
  width: fit-content;
}
.u-backgroundcolor-white {
  background-color: white;
}
.u-fixed {
  position: fixed;
  z-index: 999;
}
.u-color-white {
  color: white;
}
.overflow-hidden{
  overflow: hidden;
}
