.c-article-post-wrap-link {
  @include breakPoint(sm) {
    width: calc(50% - 8px);
  }
}
.c-article-post-lists {
  margin-bottom: 53px;
  gap: 35px 28px;

  @include breakPoint(sm) {
    width: 392px;
    max-width: calc(100% - 40px);
    margin: 0 auto 35px;
    gap: 18px 16px;
  }
}
.c-article-post {
  width: 330px;

  @include breakPoint(sm) {
    width: 100%;
  }

  &__image-wrap {
    margin-top: 9px;
    overflow: hidden;
    height: 181px;

    @include breakPoint(sm) {
      height: auto;
    }
  }

  &__image {
    background-repeat: no-repeat;
    background-size: cover;

    &:hover {
      transform: scale(1.05, 1.05);
      transition: 0.5s all;
    }

    @include breakPoint(sm) {
      height: 110px;
    }
  }
  &__category {
    height: 14px;
    margin-top: 18px;
    padding-left: 7px;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    color: #000;
    font-family: YuGothic, 'Yu Gothic', sans-serif;
    font-weight: 500;

    &-left-border {
      border-left: 2px solid black;
    }

    @include breakPoint(sm) {
      height: 12px;
      font-size: 12px;
      margin-top: 12px;
    }
  }
  &__title {
    margin-top: 9px;
    height: 44px;
    line-height: 1.35;
    font-size: 16px;
    font-family: YuGothic, 'Yu Gothic', sans-serif;
    font-weight: bold;

    @include breakPoint(sm) {
      font-size: 13px;
      width: 182px;
      height: 64px;
      line-height: 1.5;
    }
  }
  &__writer-info {
    margin-top: 9px;
    height: 42px;
  }
  &__writer-image {
    width: 32px;
    height: 32px;
    margin-top: 6px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 21px;
  }
  &__writer-name {
    margin-left: 9px;
    font-size: 13px;
    line-height: 42px;
    text-align: left;
    color: #868686;
    width: 289px;

    &--inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: fit-content;
      color: #868686;
      font-weight: bold;
      font-family: YuGothic, 'Yu Gothic', sans-serif;
    }

    @include breakPoint(sm) {
      width: 108px;
      height: 24px;
      font-size: 10px;
      line-height: 1.3;
      margin-top: 9px;
    }
  }
}
