/*========================================
 main
========================================*/

.l-main {
  display: block;
  padding: 15px 0;
  @include breakPoint(sm) {
    // padding: 30px 0 50px 0;
    padding: 15px 0 50px 0;
  }
}
