.c-footer-menu {
  &__title {
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    text-align: left;
    color: #fff;
    margin-bottom: 13px;
    font-family: "Marcellus";
    font-weight: normal;

    &--support {
      display: none;

      @include breakPoint(sm) {
        display: block;
      }
    }
  }
  &__list {
    height: 60px;
    border-left: 1px solid rgba(255,255,255,0.5);
  }
}
