.markdown-strong {
  font-weight: bold;
}
.markdown-underscore {
  text-decoration: underline;
}
.markdown-italic {
  font-style: italic;
}
//.markdown-hyperlink {
//}
