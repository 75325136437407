.p-search-keyword-list {
  margin: 30px auto 0;

  @include breakPoint(sm) {
    flex-wrap: wrap;
    margin: 30px 5% 0;
    padding-bottom: 30px;
    gap: 16px 0;
    justify-content: center;
  }
}
.p-search-keyword-wrap {
  background-color: white;
  height: 34px;
  line-height: 34px;
  border: 1px solid black;
  margin-right: 16px;

  &:last-child {
    margin-right: 0px;
  }
}
.p-search-keyword {
  font-size: 16px;
  text-align: left;
  color: #000;
  padding: 0 20px;
  font-weight: 500;
  font-family: YuGothic, 'Yu Gothic', sans-serif;

  @include breakPoint(sm) {
    padding: 0 15px;
  }
}
