.p-category-swiper {
  width: 1088px;
  margin: 30px auto 0;

  @include breakPoint(1088px) {
    width: 100%;
    margin: 30px 0 0 0;
    padding: 0 10px;
  }
  .swiper-wrapper {
    display: flex;
    justify-content: center;
    @include breakPoint(1088px) {
      justify-content: flex-start;
    }
  }
}
.p-category-swiper .swiper-slide {
  height: 130px;
  width: 100px;
  &:first-of-type {
    margin-left: 30px;
    @include breakPoint(1088px) {
      margin-left: 0;
    }

  }
}

