/*==================================
 mixin
==================================*/

/*== Responsive ====================*/
@mixin breakPoint($media-width) {
  @if $media-width == md {
    @media only screen and (max-width: $bp-md) {
      @content;
    }
  }
  @else if $media-width == sm {
    @media only screen and (max-width: $bp-sm) {
      @content;
    }
  }
  @else if $media-width == xs {
    @media only screen and (max-width: $bp-xs) {
      @content;
    }
  }
  @else {
    @media only screen and (max-width: $media-width) {
      @content;
    }
  }
}

/*== font ====================*/
@font-face {
  font-family: "Futura";
  src: url("fonts/FuturaStd-Medium.otf") format("opentype");
}
@font-face {
  font-family: "FuturaOblique";
  src: url("fonts/FuturaStd-MediumOblique.otf") format("opentype");
}
@mixin futura {
  font-family: "Futura",sans-serif;
}
@mixin futuraOblique {
  font-family: "FuturaOblique",sans-serif;
}

/*== flexbox ====================*/
// display: flex
@mixin flex {
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
}
// flex-direction
@mixin flexDirection($value: row) {
  @if $value==rowR {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
  }
  @else if $value==col {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
  }
  @else if $value==colR {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
  }
  @else {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
  }
}
// flex-wrap（基本：nowrap）
@mixin flexWrap($value: nowrap) {
  @if $value==wrap {
    flex-wrap: $value;
    -ms-flex-wrap: $value;
    -webkit-flex-wrap: $value;
  }
  @else {
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
  }
}
// justify-content（基本：start）
@mixin justifyContent($value: start) {
  @if $value==sb {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  @else if $value==sa {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  @else if $value==end {
    webkit-box-pack: end;
  	-ms-flex-pack: end;
    justify-content: flex-end;
  }
  @else if $value==center {
    justify-content: center;
    -ms-justify-content: center;
    -webkit-justify-content: center;
  }
  @else {
    justify-content: start;
    -ms-justify-content: start;
    -webkit-justify-content: start;
  }
}
// align-items（基本：stretch）
@mixin alignItems($value: stretch) {
  @if $value==start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  @else if $value==end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  @else if $value==center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  @else if $value==baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  @else {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}
// align-content（基本：stretch）
@mixin alignContent($value: stretch) {
  @if $value==start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  @else if $value==end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  @else if $value==center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  @else if $value==sb {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  @else if $value==sa {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  @else {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
}
// order（数値）
@mixin order($value) {
  -webkit-box-ordinal-group: $value;
  -ms-flex-order: $value;
  order: $value;
}
// flex-basis（数値）
@mixin flexBasis($value: auto) {
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}
// flex-grow（数値）
@mixin flexGrow($value) {
  -webkit-box-flex: $value;
  -ms-flex-positive: $value;
  flex-grow: $value;
}
// align-self（基本：auto）
@mixin alignSelf($value: stretch) {
  @if $value==start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  @else if $value==end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  @else if $value==center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  @else if $value==baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  @else if $value==stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  @else {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
}
