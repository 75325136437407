.p-top-description-swiper {
  width: 1388px;
  margin-left: 9%;

  @include breakPoint(sm) {
    width: 784px;
    margin-left: calc(50% - 784px/2);
  }

  &__operation {
    width: 37%;
    max-width: 500px;
    height: 59px;
    padding-top: 15px;
    border-top: 2px solid  rgba(0,0,0,0.2);
    position: absolute;
    left: 120px;
    bottom: 0;

    @include breakPoint(sm) {
      width: 100%;
      border-top: none;
      left: 0;
    }
  }
}
.p-top-description-swiper .swiper-slide {
  height: 401px;
  margin-top: 40px;

  @include breakPoint(sm) {
    width: 248px !important;
    height: 330px;
    margin-top: 0;
  }
}
.p-top-description-swiper .swiper-slide-active {
  width: 398px !important;
  height: 530px;
  margin-top: 0px;

  @include breakPoint(sm) {
    width: 248px !important;
    height: 330px;
    margin-top: 0px;
  }
}
.p-top-description-swiper .swiper-pagination {
  margin-top: 15px;

  @include breakPoint(sm) {
    margin-left: 20px;
  }
}
.p-top-description-swiper .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin-right: 16px;
}
.p-top-description-swiper .swiper-pagination-bullet-active {
  opacity: 1;
  background: black;
}
.p-top-description-swiper .swiper-button-next, .p-top-description-swiper .swiper-button-prev {
  position: absolute;
  top: auto;
  bottom: 0;
  width: 42px;
  height: 42px;
  border: 1px solid black;
  z-index: 10;
  cursor: pointer;
  background-size: 7px 13px;
  background-position: center;
  background-repeat: no-repeat;
}
.p-top-description-swiper .swiper-button-prev {
  background-color: black;
  left: auto;
  right: 70px;
}
.p-top-description-swiper .swiper-button-next {
  right: 20px;
}
