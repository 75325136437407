body {
  // margin-top: -32px !important;
  .p-singleAd__desc {
    br {
      height: 0;
    }
  }
}
.screen-reader-text {
  display: none;
}
