/*========================================
 search
========================================*/

.c-search {
  @include flex;
  @include alignItems(center);
  width: 630px;
  padding: 0 20px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid $color_gray03;
  border-radius: 100px;
  outline: none;
  @include breakPoint(sm) {
    width: 100%;
  }
  &__button {
    display: block;
    padding: 0;
    margin-right: 10px;
    background-color: transparent;
    border: 0;
    outline: none;
    img {
      width: 20px;
      height: auto;
    }
  }
  &__input {
    display: block;
    width: 100%;
    padding: 14px 0;
    font-size: 1.6rem;
    background-color: transparent;
    border: 0;
    outline: none;
  }
}
