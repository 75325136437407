.c-menu-modal-menu {
  &__title {
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 25px;
    font-family: serif;
    font-weight: 100;
    font-family: "Marcellus";
    font-weight: normal;

    @include breakPoint(sm) {
      height: 25px;
      font-size: 25px;
      line-height: 25px;
    }

    &--support {
      display: none;
    }
  }
  &__list {
    border-left: 1px solid #868686;
    padding-left: 30px;

    @include breakPoint(sm) {
      flex-wrap: wrap;
      gap: 10px 0;
    }

    &--category {
      width: 420px;

      @include breakPoint(sm) {
        width: 240px;
      }
    }
  }
}
