// /*==================================
//  Product
// ==================================*/

.container {
  width: 100%;
  .p-singleProductImg {
    display: block;
    max-width: 100%;
    height: 750px;
    margin: 0 auto;
    object-fit: contain;
    @include breakPoint(xs) {
      max-width: 100%;
      max-height: 350px;
      margin: 0 auto;
    }
  }
}
.product-slider-thumbnails {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  height: auto;
  margin-top: 15px;
  overflow: hidden;
  .product-thumbnail-image {
    object-fit: contain;
    width: 40px;
    height: 40px;
    margin: 0 10px 5px 0;
    border-radius: 0;
    cursor: pointer;
  }
}
.swiper-slide-thumb-active {
  border: 2px solid #000;
  border-radius: 2px;
}

.product-navigation {
  opacity: 0.6;
  background-size: 20px;
}

.p-product_price {
  font-size: 16px;
}

.p-productLinkBotton {
  display: inline-block;
  width: 100%;
  height: 42px;
  margin: 5px 0 5px 0;
  padding: 5px 0;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: .1em;
  text-decoration: none !important;
  &:hover{
    opacity: 0.8;
  }
  .p-shop_price {
    font-size: 11px;
    font-weight: normal;
    margin-top: 4px;
  }
}

.p-product-detail-button {
  position: fixed;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 10px;
  z-index: 9999;
  box-sizing: border-box;
  display: block;;
  background: #FFC56D;
  text-align: center;

  .p-product-detail-text {
    color: #FFF;
    font-weight: 700;
    text-decoration: none;
  }
  &:after {
    content: ' ';
    position: absolute;
    bottom: 8px;
    right: 40px;
    width: 0;
    height: 0;
    border: none;
    border-bottom: 15px solid #FFF;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
}

.p-dropdown_list {
  display: none;
}

.is-open:not(.gNavi-ul-li) {
  display: block;
  position: absolute;
  bottom: 45px;
  z-index: 9999999;
}
