/*========================================
 tags
========================================*/

.p-tags {
  @include flex;
  @include flexWrap(wrap);
  &__tag {
    margin-right: 17px;
    line-height: 2;
    font-size: 1.4rem;
    transition: .3s;
    &:hover {
      opacity: .5;
      @include breakPoint(md) {
        opacity: 1;
      }
    }
  }
}
