.p-article-tab-content-wrap {
  width: 1046px;
  padding-bottom: 100px;
  margin: 72px auto 0;

  @include breakPoint(sm) {
    width: 100%;
    padding-bottom: 50px;
    margin: 47px auto 0;
  }
}
