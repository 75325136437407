/*========================================
 card
========================================*/

// normal
.p-card {
  display: block;
  border: 1px solid #D5D5D5;
  box-shadow: 0 1px 6px rgba(0,0,0,.16);
  &:hover {
    .p-card__thumb {
      opacity: .5;
    }
  }
  &--ranking {
    position: relative;
    margin-top: 30px;
    border: none;
  }
  &__numSpace {
    position: absolute;
    z-index: 1;
  }
  &__numTriangle {
    border-top: 50px solid white;
    border-right: 100px solid transparent;

    @include breakPoint(xs) {
      border-top: 30px solid white;
      border-right: 70px solid transparent;
    }
  }
  &__num {
    position: absolute;
    font-size: 50px;
    top: -15px;
    left: 5px;

    @include breakPoint(xs) {
      font-size: 30px;
      top: -10px;
      left: 2px;
    }
  }
  &__thumb {
    transition: .3s;

    .product-label {
      display: block;
      width: 30px;
      height: 30px;
      background-color: #fff200;
      position: absolute;
      text-align: center;
      font-size: 16px;
      padding-top: 8px;
    }
  }
  &__thumbImg {
    display: block;
    width: 100%;
    aspect-ratio: 1.78;
  }
  &__label {
    padding: 20px;
    background-color: $color_white;
    @include breakPoint(xs) {
      padding: 10px;
    }
  }
  &__title {
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.6rem;
    @include breakPoint(xs) {
      font-size: 1.3rem;
    }
  }
  &__infoItem {
    display: inline-block;
    color: $color_gray01;
    font-size: 1.3rem;
    @include breakPoint(xs) {
      font-size: 1rem;
    }
  }
  &__infoItem--category {
    color: $color_base;
  }

  .pr-tag {
    display: inline-block;
    color: #212121;
    font-size: 1.3rem;
  }
}

// emphasis
.p-cardEmphasis {
  position: relative;
  display: block;
  border: 1px solid #D5D5D5;
  box-shadow: 0 1px 6px rgba(0,0,0,.16);
  .pr-tag {
    position: absolute;
    left: 10px;
    top: 10px;
    padding: 2px 8px;
    line-height: 1.5;
    font-size: 1.3rem;
    color: #868686;
    border: 1px solid #D5D5D5;
    background-color: #ffffff;
  }
  &:hover {
    .p-cardEmphasis__thumb {
      opacity: .5;
    }
  }
  &__thumb {
    width: 100%;
    height: 100%;
    transition: .3s;
  }
  &__info {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px;
    background-color: $color_white;
  }
  &__infoItem {
    display: inline-block;
    color: $color_gray01;
    font-size: 1.3rem;
    @include breakPoint(xs) {
      font-size: 1rem;
    }
  }
  &__infoItem--category {
    color: $color_base;
  }
  &__label {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    background-color: rgba(255,255,255,.9);
    @include breakPoint(xs) {
      padding: 10px;
    }
  }
  &__title {
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 2rem;
    font-weight: bold;
    @include breakPoint(xs) {
      font-size: 1.6rem;
    }
  }
  &__desc {
    line-height: 1.5;
    font-size: 1.3rem;
    color: $color_gray01;
    @include breakPoint(xs) {
      font-size: 1.2rem;
    }
  }
}

// discreet
.p-cardDiscreet {
  display: block;
  width: 100%;
  @include flex;
  &:hover {
    .p-cardDiscreet__label {
      .p-cardDiscreet__title {
        opacity: .5;
      }
    }
  }
  &__thumb {
    position: relative;
  }
  &__rank {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    padding: 6px 10px;
    text-align: center;
    font-size: 2rem;
    @include futura;
    background-color: $color_white;
  }
  &__thumbImg {
    width: 100px;
    height: 100px;
    @include breakPoint(xs) {
      width: 80px;
      height: 80px;
    }
  }
  &__title {
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 1.4rem;
    font-weight: bold;
    transition: .3s;
    @include breakPoint(xs) {
      font-size: 1.5rem;
    }
  }
  &__label {
    margin-left: 10px;
    background-color: $color_white;
  }
  &__category {
    display: inline-block;
    padding: 2px 8px;
    text-align: center;
    line-height: 1.5;
    font-size: 1.3rem;
    color: $color_gray01;
    border: 1px solid #D5D5D5;
  }
  &__infoItem {
    display: inline-block;
    color: $color_gray01;
    font-size: 1.3rem;
  }
  &__infoItem--category {
    color: $color_base;
  }
  .pr-tag {
    display: inline-block;
    padding: 2px 8px;
    text-align: center;
    line-height: 1.5;
    font-size: 1.3rem;
    color: $color_gray01;
    border: 1px solid #D5D5D5;
  }
}

.p-featureCard {
  display: block;
  border: 1px solid #D5D5D5;
  box-shadow: 0 1px 6px rgba(0,0,0,.16);
  width: 48%;
  @include breakPoint(xs) {
    width: 100%;
  }
  &:hover {
    .p-featureCard__thumb {
      opacity: .5;
    }
  }

  &__thumb {
    transition: .3s;
  }
  &__thumbImg {
    display: block;
    width: 100%;
    height: 100%;
  }
  &__label {
    padding: 15px;
    background-color: $color_white;
    @include breakPoint(xs) {
      padding: 10px;
    }
  }
  &__title {
    line-height: 1.5;
    font-size: 1.6rem;
    @include breakPoint(xs) {
      font-size: 1.3rem;
    }
  }
  &--post {
    width: 32%;
    @include breakPoint(xs) {
      width: 48%;
    }
  }
}

