.l-search-sidetab {
  margin-right: -100%;
  width: 500px;
  height: 100vh;
  background-color: #f7f7f7;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;

  @include breakPoint(sm) {
    width: 100%;
  }
}
