/*========================================
 sectionTitle
========================================*/

.p-sectionTitle {
  @include flex;
  @include alignItems(end);
  padding-bottom: 16px;
  margin-bottom: 20px;
  border-bottom: 1px solid $color_base;
  &__en {
    @include futuraOblique;
    font-size: 3.2rem;
    @include breakPoint(xs) {
      font-size: 2.6rem;
    }
  }
  &__ja {
    line-height: 1.5;
    margin-left: 12px;
    font-size: 1.8rem;
    @include breakPoint(xs) {
      font-size: 1.4rem;
    }
  }
}
