.p-professional {
  &__image {
    width: 100%;
    height: 212px;
  }
  &__title {
    height: 14px;
    margin-top: 9px;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: #868686;
    font-weight: bold;
    font-family: YuGothic, 'Yu Gothic', sans-serif;
  }
  &__name {
    height: 18px;
    margin-top: 6px;
    font-size: 18px;
    line-height: 18px;
    text-align: left;
    color: #030303;
    font-weight: bold;
    font-family: YuGothic, 'Yu Gothic', sans-serif;
  }
}
