/*========================================
 writer
========================================*/
.writer-card {
  box-shadow: 0 1px 6px rgba(0,0,0,.16);
  width: 48%;
  padding: 20px;
  display: flex;
  @include breakPoint(xs) {
    width: 100%;
  }
}
.writer-introduction {
  padding-top: 0.5em;
}

.writer-wrap {
  padding: 2% 3% 2%;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
}
.writer-profile-wrap {
  position: relative;
}
.writer-ttl {
  margin-bottom: 0.5em;
  text-align: left;
  font-weight: bold;
  font-size: 2.0rem;
  font-family: "FuturaOblique",sans-serif;
}

.writer-avatar-wrap {
  font-family: "helvetica", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", sans-serif;
  float: left;
  margin: 0 1.0em 0.5em 0;
}
.writer-avatar {
  border-radius: 50%;
}
.writer-name-wrap {
  height: 50px;
  p {
    height: 25px;
    line-height: 25px;
  }
}
.writer-detail-profile {
  font-size: 15px;
  margin: 10px 0;
  clear: both;
  line-height: 1.5;
}
.writer-position {
  font-size: 1.1rem;
  color: black;
  background-color: #e5e5e5;
  display: inline-block;
  line-height: 1.8;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 7px;
}
.writer-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.writer-profile-links {
  position: relative;
  margin-top: 5px;

  .writer-profile-link {
    margin: 0 10px 10px 0;

    &__twitter {
      float: left;
    }
    &__instagram {
      float: left;
    }
    &__other {
      margin-top: 10px;
      border: 1px solid #A9A9A9;
      padding: 6px;
    }
    &__other-name {
      font-size: 13px;
      line-height: 16px;
    }
  }
  .dummy-div {
    clear: both;
  }
  .sns-icon {
    color: grey;
  }
  .sns-icon:before {
    color: grey;
  }
  .sns-icon:after {
    color: grey;
  }
}
.writer-detail-link:hover {
  text-decoration: none;
}


.p-topWriter {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;


  &__writer {
    text-align: center;
    @include breakPoint(xs) {
      width: 48%;
      margin-bottom: 10px;
    }
  }
  &__name {
    padding-top: 20px;
    text-align: center;
    font-size: 20px;
  }

  &__avatar  {
    width: 130px;
    height: 130px;
    border-radius: 50%;
  }
}
