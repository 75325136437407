/*==================================
 variable
==================================*/

/*== Responsive ====================*/
$bp-md: 1024px;
$bp-sm: 768px;
$bp-xs: 640px;

/*== Color ====================*/
$color_base: #212121;
$color_gray01: #868686;
$color_gray02: #B4B4B4;
$color_gray03: #D5D5D5;
$color_gray04: #E0E0E0;
$color_gray05: #F8F8F8;
$color_gray06: #F2F2EC;
$color_white: #ffffff;
$color_smoke: #F2F2EC;
$color_yellow: #CFA32A;
$color_red: #FC5252;
$color_amazon: #F79C30;
$color_rakuten: #BF0000;
$color_product: #13CBE3;
$color_entry: #64DD17;
