.p-menu-modal-header {
  width: 100%;
  height: 70px;
  position: fixed;
  background-color: white;
}

.p-menu-modal-activel-logo {
  width: 110px;
  height: 40px;
  margin: 25px 0 0 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('images/flactive/about_logo.png');

  @include breakPoint(sm) {
    width: 80px;
    height: 30px;
    margin: 20px 0 20px 25px;
  }
}
.p-menu-modal-content {
  &__top {
    margin-left: 200px;

    @include breakPoint(sm) {
      margin-left: 40px;
    }

    &--experience {
      margin-top: 130px;

      @include breakPoint(sm) {
        margin-top: 90px;
      }
    }
    &--article {
      margin-top: 60px;

      @include breakPoint(sm) {
        margin-top: 45px;
      }
    }
    &--category {
      margin-top: 60px;

      @include breakPoint(sm) {
        margin-top: 45px;
      }
    }
  }
}
.p-menu-modal-list-item {
  &__experience {
    width: fit-content;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
    font-weight: 700;
    font-family: sans-serif;
    margin-right: 30px;

    @include breakPoint(sm) {
      width: 120px;
    }

    &--en {
      font-weight: 100;
      color: #868686;
      font-family: "Marcellus";
      font-weight: normal;

      @include breakPoint(sm) {
        font-size: 15px;
      }
    }
  }
  &__article {
    width: fit-content;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
    font-weight: 700;
    font-family: sans-serif;
    margin-right: 30px;

    @include breakPoint(sm) {
      width: 100px;
      font-size: 16px;
    }
  }
  &__category {
    width: 84px;
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    margin: 7px 11px 10px 0;
    font-family: sans-serif;
    font-weight: 700;

    @include breakPoint(sm) {
      margin: 5px 11px 5px 0;
    }
  }
}
.p-menu-modal-footer {
  margin-top: 60px;
  margin-left: 200px;
  border-top: 1px solid #868686;
  width: 1000px;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;

  @include breakPoint(sm) {
    justify-content: flex-start;
    display: block;
    width: 90%;
    margin: 40px auto;
  }
}
.p-menu-modal-sns-list {
  width: 330px;
  height: 30px;

  @include breakPoint(sm) {
    margin: 0 auto;
  }
}
.p-menu-modal-info-list {
  list-style: none;
  padding-left: 0;
  font-size: 11px;
  height: fit-content;
  margin-top: 8px;

  @include breakPoint(sm) {
    margin: 25px auto 0;
    flex-wrap: wrap;
    gap: 15px 30px;
    font-size: 12px;
    width: 320px;
  }

  &__item {
    margin-left: 30px;
    color: white;
    font-family: sans-serif;

    @include breakPoint(sm) {
      margin-left: 0;
    }

    &--last {
      margin-right: 0;
    }
  }
}
