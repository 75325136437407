.p-featureCard {
  display: block;
  border: 1px solid #D5D5D5;
  box-shadow: 0 1px 6px rgba(0,0,0,.16);
  width: 48%;
  aspect-ratio: 1.78;
  @include breakPoint(xs) {
    width: 100%;
  }
  &:hover {
    .p-featureCard__thumb {
      opacity: .5;
    }
  }

  &__thumb {
    transition: .3s;
  }
  &__thumbImg {
    display: block;
    width: 100%;
    height: 100%;
  }
  &__label {
    padding: 15px;
    background-color: $color_white;
    @include breakPoint(xs) {
      padding: 10px;
    }
  }
  &__title {
    line-height: 1.5;
    font-size: 1.6rem;
    @include breakPoint(xs) {
      font-size: 1.3rem;
    }
  }
  &--post {
    width: 32%;
    @include breakPoint(xs) {
      width: 48%;
    }
  }
}

