.l-footer {
  height: 253px;
  background-color: black;
  padding-top: 60px;

  @include breakPoint(sm) {
    height: auto;
    padding-top: 44px;
  }
}

.l-footer a:hover {
  color: white !important;
}
