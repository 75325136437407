.p-errorPage {
  &__container {
    margin: 50px auto;
    max-width: 1000px;
    text-align: center;
  }
  &__logo {
    margin: 0 auto 30px;
    display: block;
    max-width: 200px;
    max-height: 200px;
    &--img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__title {
    color: $color_base;
    font-size: 11rem;
    font-weight: 600;
    letter-spacing: 5px;
    margin-top: 80px;
    margin-bottom: 0;
    opacity: 0.9;
    opacity: .9;
  }

  &__subTitle {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 20px;
    letter-spacing: 2px;
    line-height: 2;
  }

  &__text {
    line-height: 2;
    padding: 10px;
    font-size: 1.4rem;
  }

  &__link {
    width: 200px;
    font-size: 1.4rem;
    margin: 20px auto;
    color: #212121;
    border: 1px solid #212121;
    z-index: 2;
    position: relative;
    display: block;
    height: 50px;
    padding: 0;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    background-color: #ffffff;
    outline: none;
    transition: .3s;
    // &:hover {
    //   opacity: .8;
    //   text-decoration: none;
    //   color: #fff;
    // }
  }
}
