/*==================================
 single
==================================*/

// singleTitle
.p-singleTitle {
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 2.6rem;
  font-weight: bold;
  @include breakPoint(xs) {
    font-size: 2.2rem;
  }
}
.p-singleEyecatchImg {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
}
.writer-avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;

}

// singleInfo
.p-singleInfo {
  text-align: right;
  margin-bottom: 30px;
  &__item {
    display: inline-block;
    color: $color_gray01;
    font-size: 1.3rem;
  }
  &__item {
    &--category {
      color: $color_base;
    }
  }
}

.p-singleTags {
  display: flex;
  flex-wrap: wrap;
  &--tag {
    font-size: 1.2rem;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

// singleImg
.p-singleImg {
  display: block;
  object-fit: cover;
  max-width: 100%;
  max-height: 350px;
  margin: 0 auto;
  @include breakPoint(xs) {
    max-width: 100%;
    margin: 0 auto;
  }
  &__credit {
    font-size: 1.3rem;
    margin: 5px 0 20px;
    width: 100%;
    a {
      color: $color_gray01;
    }
  }
}

// singleText
.p-singleText {
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 1.4rem;
}

// singleList
.p-singleList {
  position: relative;
  width: 100%;
  padding: 40px;
  margin-bottom: 40px;
  border: 1px solid $color_gray04;
  @include breakPoint(xs) {
    padding: 40px 20px 20px 20px;
  }
  &__titleWrap {
    position: absolute;
    top: -6px;
    left: 26px;
    background-color: $color_white;
  }
  &__title {
    display: block;
    padding: 0 12px;
    font-size: 1.6rem;
    font-weight: bold;
  }
  &__listItem {
    position: relative;
    margin-bottom: 10px;
    padding-left: 16px;
    line-height: 1.5;
    font-size: 1.4rem;
    &:last-child {
      margin-bottom: 0;
    }
    &::before {
      content: "";
      left: 0;
      position: absolute;
    }
  }
  &__link {
    transition: .3s;
    &:hover {
      opacity: .5;
      @include breakPoint(xs) {
        opacity: 1;
      }
    }
  }
}
.p-singleList--ul {
  .p-singleList__listItem {
    &::before {
      top: 9px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $color_base;
    }
  }
}
.p-singleList--ol {
  counter-reset:number;
  .p-singleList__listItem {
    &::before {
      counter-increment: number;
      content: counter(number);
      top: 0;
      @include futuraOblique;
      font-size: 1.6rem;
      font-style: normal;
    }
  }
}
.p-singleList--index {
  margin-top: 12px;
  .p-singleList__title {
    position: relative;
    padding-left: 26px;
    @include futuraOblique;
    font-size: 2rem;
    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 0;
      bottom: 0;
      left: 7px;
      margin: auto 0;
      width: 12px;
      height: 13px;
      background-image: url("images/common/icon/index.svg");
      background-size: cover;
    }
  }
  .p-singleList__listItem {
    border-bottom: 1px dotted $color_gray04;
    &::before {
      top: 8px;
      width: 8px;
      height: 8px;
      background-color: $color_base;
    }
  }
}

// singleH2
.p-singleH2 {
  position: relative;
  padding: 20px;
  margin-bottom: 30px;
  line-height: 1.5;
  font-size: 2.1rem;
  font-weight: bold;
  border: 2px solid $color_base;
  @include breakPoint(xs) {
    font-size: 2rem;
  }
  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 6px;
    left: 6px;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #F2F2EC;
  }
}

// singleH3
.p-singleH3 {
  padding: 6px 0 12px 20px;
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 1.8rem;
  font-weight: bold;
  border-left: 3px solid $color_base;
  border-bottom: 1px dotted $color_base;
}

// singleH4
.p-singleH4 {
  padding: 0 0 12px 0;
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 1.4rem;
  font-weight: bold;
  border-bottom: 1px solid $color_base;
}

// singleSplitcontents
.p-singleSplitContents {
  @include flex;
  @include justifyContent(sb);
  @include breakPoint(xs) {
    @include flexDirection(col);
  }
  &__imageBlock {
    display: block;
    width: 48%;
    text-align: center;
    @include breakPoint(xs) {
      width: 100%;
    }
  }
  &__img {
    max-width: 100%;
    margin-bottom: 10px;
  }
  &__contentBlock {
    width: 48%;
    margin-bottom: 10px;
    @include breakPoint(xs) {
      width: 100%;
    }
  }
  &__text {
    line-height: 1.5;
    font-size: 1.4rem;
    @include breakPoint(xs) {
      width: 100%;
    }
  }
  &__heading {
    padding: 0 0 8px 0;
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 1.4rem;
    font-weight: bold;
    border-bottom: 1px solid $color_base;
  }
}

// singleIframe
.p-singleIframe {
  position: relative;
  // height: 0;
  margin-bottom: 20px;
  // padding-bottom: 56.25%;
  overflow: hidden;
  @include flex;
  flex-direction: column;
  @include justifyContent(sb);
  @include breakPoint(xs) {
    @include flexDirection(col);
  }
  &__content {
    width: auto;
    @include breakPoint(xs) {
      width: 100%;
    }
  }
  &__description {
    width: auto;
    line-height: 1.5;
    font-size: 1.4rem;
    padding: 20px 0 0 10px;
    @include breakPoint(xs) {
      width: 100%;
      padding: 10px 0;
    }
  }
  &--youtube {
    iframe {
      width: 100%;
      height: 100%;
      // height: 400px;
        aspect-ratio: 16 / 9;
      @include breakPoint(xs) {
        width: 100%;
        // aspect-ratio: 16 / 9;
        // height: 230px;
      }
    }
  }
}

.p-singleButton {
  margin-bottom: 30px;
}

// singleAd
.p-singleAd {
  margin-bottom: 30px;
  border: 1px solid $color_gray02;

  &__flexWrapper {
    @include flex;
    @include justifyContent(sb);
    @include breakPoint(sm) {
      @include flexDirection(col);
    }
  }
  &__image {
    padding: 20px;
    display: inline-block;
    width: 55%;
    box-sizing: border-box;
    vertical-align: top;
    text-align: center;
    @include breakPoint(sm) {
      width: 100%;
      max-width: 100%;
      padding: 15px 10px 0;
    }
  }
  &__thumb {
    width: 300px;
    max-width: 300px;
    height: 230px;
    max-height: 230px;
    line-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    @include breakPoint(sm) {
      width: 100%;
      max-width: 300px;
      height: 235px;
      max-height: 300px;
      margin: 0 auto;
    }
  }
  &__credit {
    display: block;
    padding: 10px;
    text-align: left;
  }
  &__contents {
    padding: 20px 10px;
    padding-left: 0;
    width: 45%;
    @include breakPoint(sm) {
      width: 100%;
      padding: 20px 10px;
    }
  }
  &__brand {
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 1.4rem;
  }
  &__name {
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 1.8rem;
    font-weight: bold;
  }
  &__price {
    margin-bottom: 10px;
    line-height: 1.5;
    color: $color_gray01;
    font-size: 1.4rem;
  }
  &__desc {
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 1.3rem;
    padding: 20px 30px;
    @include breakPoint(sm) {
      padding: 20px 10px;
    }
  }
  &__buttonWrap {
    a {
      // width: 80%;
      width: 90%;
      margin-bottom: 10px;
      @include breakPoint(sm) {
        width: 100%;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// singleQuote
.p-singleQuote {
  padding: 66px 24px 24px 24px;
  margin-bottom: 40px;
  background-image: url("images/single/quote.png");
  background-size: cover;
  &__text {
    line-height: 1.5;
    font-size: 1.3rem;
  }
  &__source {
    line-height: 1.5;
    text-align: right;
    font-size: 1.3rem;
    color: $color_gray01;
  }
}

// singleTableWrap
.p-singleTableWrap {
  margin-bottom: 40px;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  .p-singleTable {
    &__caption {
      padding: 16px;
      line-height: 1.5;
      font-size: 1.6rem;
      font-weight: bold;
      border: 1px solid $color_gray04;
      border-bottom: none;
    }
    &__tr {
      &:nth-child(odd) {
        background-color: $color_gray05;
      }
    }
    &__item {
      padding: 12px;
      line-height: 1.5;
      font-size: 1.4rem;
      border: 1px solid $color_gray04;
    }
    &__item--th {
      font-weight: bold;
      border-top: none;
    }
  }
}

// singleInstaSimple
.p-singleInstaSimple {
  width: 300px;
  margin: 0 auto 20px 0;
  @include breakPoint(xs) {
    width: 100%;
  }
  &__img {
    display: block;
    width: 100%;
  }
  &__label {
    padding: 6px 10px;
    border: 1px solid $color_gray04;
  }
  &__logo {
    display: block;
    width: 81px;
    height: auto;
  }
}

// singlePoint
.p-singlePoint {
  padding: 36px 24px 24px 24px;
  margin-bottom: 20px;
  border: 1px solid $color_base;
  &__textEmphasis {
    position: relative;
    padding: 20px 16px 16px 16px;
    margin-bottom: 16px;
    line-height: 1.5;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: $color_gray05;
    &::before {
      content: "";
      position: absolute;
      top: -16px;
      right: 0;
      left: 0;
      margin: 0 auto;
      width: 126px;
      height: 26px;
      background-image: url("images/single/point.svg");
      background-size: cover;
    }
  }
  &__text {
    line-height: 1.5;
    color: $color_gray01;
    font-size: 1.3rem;
  }
}

// singleInstaSplit
.p-singleInstaSplit {
  margin-bottom: 30px;
  border: 1px solid $color_gray04;
  &__wrap {
    @include flex;
    @include breakPoint(sm) {
      @include flexDirection(col);
    }
  }
  &__img {
    display: block;
    width: 50%;
    @include breakPoint(sm) {
      width: 100%;
    }
  }
  &__contents {
    width: 50%;
    padding: 20px;
    @include breakPoint(sm) {
      width: 100%;
    }
  }
  &__author {
    @include flex;
  }
  &__avatar {
    display: block;
    width: 33px;
    height: 33px;
    margin-right: 7px;
    border-radius: 50%;
  }
  &__nameWrap {
    margin-bottom: 20px;
  }
  &__name,
  &__id {
    line-height: 1.5;
    font-size: 1.2rem;
  }
  &__name {
    font-weight: bold;
  }
  &__text {
    line-height: 1.5;
    font-size: 1.3rem;
  }
  &__label {
    @include flex;
    @include justifyContent(sb);
    padding: 12px;
    border-top: 1px solid $color_gray04;
  }
  &__postDate {
    color: $color_gray01;
    font-size: 1.2rem;
  }
  &__logo {
    display: block;
    width: 15px;
    height: auto;
  }
}

// singleTwitter
.p-singleTwitter {
  width:100%;
  margin-bottom: 30px;
}

// singleComment
.p-singleComment {
  margin-bottom: 30px;
  &__author {
    @include flex;
  }
  &__avatar {
    display: block;
    width: 33px;
    height: 33px;
    margin-right: 7px;
    border-radius: 50%;
  }
  &__nameWrap {
    margin-bottom: 20px;
  }
  &__name,
  &__status {
    line-height: 1.5;
    font-size: 1.2rem;
  }
  &__textWrap {
    position: relative;
    &:before,
    &:after{
      content: "";
      position: absolute;
      width: 0;
      height: 0;
    }
    &:before{
      top: -14px;
      left: 20px;
      border-style: solid;
      border-color: transparent transparent $color_gray02 transparent;
      border-width: 0 20px 15px 0;
    }
    &:after{
      z-index: 10;
      top: -12px;
      left: 21px;
      border-style: solid;
      border-color: transparent transparent $color_white transparent;
      border-width: 0 17px 13px 0;
    }
  }
  &__text {
    position: relative;
    padding: 26px;
    line-height: 1.5;
    font-size: 1.3rem;
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 26px;
      border-left: 1px solid $color_gray02;
      border-right: 1px solid $color_gray02;
    }
    &::before {
      top: 0;
      border-top: 1px solid $color_gray02;
    }
    &::after {
      bottom: 0;
      border-bottom: 1px solid $color_gray02;
    }
  }
}

// singleRelated
.p-singleRelated {
  position: relative;
  display: block;
  margin: 60px 0 30px 0;
  border: 1px solid $color_yellow;
  transition: .3s;
  &:hover {
    opacity: .5;
    @include breakPoint(sm) {
      opacity: 1;
    }
  }
  &__labelWrap {
    position: absolute;
    top: -24px;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: block;
    width: 127px;
  }
  &__label {
    position: relative;
    padding: 7px 33px;
    text-align: center;
    color: $color_yellow;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: $color_white;
    border: 1px solid $color_yellow;
    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: -8px;
      width: 0;
      height: 0;
      border-style: solid;
    }
    &::before {
      left: 0;
      border-width: 7px 0px 0 15px;
      border-color: $color_yellow transparent transparent transparent;
    }
    &::after {
      right: 0;
      border-width: 7px 15px 0 0px;
      border-color: $color_yellow transparent transparent transparent;
    }
  }
  &__splitContent {
    @include flex;
    padding: 28px 20px 20px 20px;
  }
  &__thumb {
    display: block;
    width: 90px;
    height: 90px;
    margin-right: 16px;
  }
  &__content {

  }
  &__title {
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 1.6rem;
    font-weight: bold;
  }
  &__desc {
    line-height: 1.5;
    color: $color_gray01;
    font-size: 1.3rem;
  }
}

// singlePagination
.p-singlePagination {
  margin-bottom: 10px;
  &__buttonWrap {
    @include flex;
    @include justifyContent(center);
    margin-bottom: 20px;
  }
  &__button {
    display: block;
    width: 48%;
    text-align: center;
    font-size: 1.6rem;
    border: 1px solid $color_yellow;
    border-radius: 2px;
    margin: 0 2px;
    &:active {
      opacity: .5;
    }
    &:hover {
      span {
        &::before {
          left: 11px;
          @include breakPoint(md) {
            left: 15px;
          }
        }
        &::after {
          right: 11px;
          @include breakPoint(md) {
            right: 15px;
          }
        }
      }
    }
    span {
      position: relative;
      display: block;
      padding: 17px;
      margin: 5px;
      @include futura;
      font-style: normal;
      &::before,
      &::after {
        position: absolute;
        top: 14px;
        font-family: FontAwesome;
        font-size: 2rem;
        transition: .3s;
      }
    }
  }
  &__button--prev {
    color: $color_yellow;
    background-color: $color_white;
    span {
      color: $color_yellow;
      border: 1px solid $color_yellow;
      &::before {
        content: "\f104";
        left: 15px;
        color: $color_yellow;
      }
    }
  }
  &__button--next {
    background-color: $color_yellow;
    span {
      color: $color_white;
      border: 1px solid $color_white;
      &::after {
        content: "\f105";
        right: 15px;
        color: $color_white;
      }
    }
  }
  &__locationWrap {
    @include flex;
    @include justifyContent(center);
    text-align: center;
  }
  &__location {
    @include futura;
    font-style: normal;
    font-size: 1.6rem;
    color: $color_yellow;
  }
  &__numberList {
    display: flex;
    font-size: 20px;
    margin: 0 auto;
    margin-bottom: 10px;
    width: fit-content;

    li {
      font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;
      width: 40px;
      text-align: center;
      margin: 1px;
      height: 40px;
      padding: 8px;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      cursor: pointer;
      position: relative;
    }

    li a {
      color: #CFA320;
    }
  }
  &__numberSelected {
    color: #fff;
    background-color: #CFA320;
    padding-bottom: 5px;
  }
}

.p-singleWarning {
  color: $color_gray01;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 10px 0;
}

.p-singleShareMini {
  clear: both;
  margin: 15px 0;
  overflow: hidden;

  &__item {
    display: inline-block;
    float: right;
    margin-left: 2%;
    width: 50px;
    height: 50px;

    line-height: 5rem;
    position: relative;

    &--tw {
      background-color: #00aced;
    }

    &--x {
      background-color: #000;
    }

    &--fb {
      background-color: #3b5998;
    }

    &__button {
      color: #fff;
      font-size: 1.2rem;
      font-style: normal;
      text-align: center;
      display: block;
      i:before {
        color: #fff;
      }
    }

    i {
      vertical-align: middle;
    }
    .line-icon {
      height: 50px;
      width: 50px;
      vertical-align: middle;
    }
  }
}

.p-singleShare {
  clear: both;
  margin: 15px 0;
  overflow: hidden;

  &__item {
    width: 33.3%;
    display: inline-block;
    float: left;

    position: relative;
    line-height: 4rem;

    &--tw {
      background-color: #00aced;
    }

    &--x {
      background-color: #000;
    }

    &--fb {
      background-color: #3b5998;
    }

    &--line {
      background-color: #00b900;
    }

    &__button {
      color: #fff;
      font-size: 1.2rem;
      font-style: normal;
      text-align: center;
      display: block;
      i:before {
        color: #fff;
      }
    }
  }

  i {
    vertical-align: middle;
  }
  .line-icon {
    vertical-align: middle;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    &--mobile {
      vertical-align: middle;
      height: 35px;
      width: 35px;
      margin-right: 0px;
    }
  }
}

.p-singleCharacter {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;
  justify-content: space-between;
  padding: 0 3px;
  &__contentBody {
    padding: 10px 5px;
    line-height: 1.5;
    font-size: 1.4rem;
    white-space: pre-line;
    word-wrap: break-word;
  }
  &__avatar {
    width: 80px;
    text-align: center;
    @include breakPoint(xs) {
      width: 70px;
    }
  }
  &__icon {
    img {
      width: 100%;
      height: auto;
      border-radius: 50%;
    }
  }
  &__name {
    margin-top: 8px;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 11px;
  }
  &__leftBalloon {
    padding: 8px 0;
    margin-left: 20px;
    width: 100%;
    @include breakPoint(xs) {
      max-width: 70%;
    }
    &__content {
      display: inline-block;
      position: relative;
      margin-left: 5px;
      padding: 13px 10px;
      border-radius: 12px;
      background: #e8e8e8 ;
      min-width: 100%;
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 18px;
        left: -24px;
        border: 12px solid transparent;
        border-right: 12px solid #e8e8e8;
      }
    }
  }
  &__rightBalloon {
    padding: 8px 0;
    margin-right: 20px;
    width: 100%;
    @include breakPoint(xs) {
      max-width: 70%;
    }
    &__content {
      display: inline-block;
      position: relative;
      margin-right: 5px;
      padding: 13px 10px;
      border-radius: 12px;
      background: #e8e8e8;
      min-width: 100%;
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 18px;
        right: -24px;
        border: 12px solid transparent;
        border-right: 12px solid #e8e8e8;
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }
  }
}

// singleTableTitle
.p-singleTableTitle {
  margin-bottom: 12px;
  line-height: 1.5;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  &__text {
    border-bottom: 1px solid #212121;
    padding-bottom: 0.2em;
  }
}

// // singleTable basic style
// .p-singleTableBasic {
//   table-layout: fixed;
//   margin: 0px auto 20px;
//   width: 100%;
//   font-size: 1.6rem;
//   td {
//     line-height: 1.5em;
//     padding: 0.5em;
//   }
// }
// // singleTable over 5 columns
// .p-singleTable {
//   display: block;
//   overflow-x: auto;
//   border: 1px double #B4B4B4;
//   tbody {
//     word-wrap: word-break;
//   }
//   &__cell {
//     border: 0.5px solid #e8e8e8;
//     word-wrap: break-word;
//     min-width: 150px;
//     max-width: 150px;
//     &--left {
//       text-align: left;
//     }
//     &--center {
//       text-align: center;
//     }
//     &--right {
//       text-align: right;
//     }
//   }
// }

// // singleTable under 5 columns
// .p-singleTableMini {
//   border: 1px double #B4B4B4;
//   tbody {
//     word-wrap: word-break;
//   }
//   &__cell {
//     border: 1px solid #e8e8e8;
//     &--left {
//       text-align: left;
//     }
//     &--center {
//       text-align: center;
//     }
//     &--right {
//       text-align: right;
//     }
//   }
// }

.p-singleTable {
  margin: 0px auto 20px;
  width: 100%;
  font-size: 1.3rem;
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  table {
    border: 1px double #B4B4B4;
    width: 100%;
    max-width: 100%;
    td {
      line-height: 1.5em;
      padding: 0.5em;

      border: 0.5px solid #e8e8e8;
      word-wrap: break-word;
    }
  }

  &__cell {

    &--left {
      text-align: left;
    }
    &--center {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
  }
}

// singleTable stripe
.stripe {
  background-color: #f7f7f7
}

.inner-widget {
  margin-bottom: 10px;
}

// productIntroductionImage
.p-product_introduction_container {
  max-width: 100%;
}

.p-singleProductIntroductionImg {
  display: block;
  object-fit: contain;
  width: 300px;
  height:230px;
  max-width: 100%;
  max-height: 230px;
  margin: auto;
  @include breakPoint(xs) {
    max-width: 100%;
    max-height: 350px;
    margin: auto;
  }
}

.product-introduction-main-swiper {
  @include breakPoint(xs) {
    width: 100% !important;
  }
}

.product-introduction-container-thumbs {
  width: 300px;
  right: 5px;
  margin-top: 8px;
  @include breakPoint(xs) {
    width: 100%;
    right: 0;
  }
}

.product-introduction-slider-thumbnails {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  .product-introduction-thumbnail {
    object-fit: contain;
    width: 40px;
    height: 40px;
    margin: 0 10px 5px 0;
    cursor: pointer;
    @include breakPoint(xs) {
      margin: 0 4px 5px 0;
    }
  }
}

.product-introduction-navigation {
  background-color: rgba(180, 180, 180, 0.5);
  background-size: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.p-swiper-slide-thumb-active {
  border: 1px solid #000;
}

.p-slider_caption {
	font-size: 10px;
	position: absolute;
	bottom: 3px;
	right: 10px;
  line-height: 1;
  color: #c7c7c7;

  a {
    color: #c7c7c7;
    text-decoration: none;
  }
}

.product_attribute {
  font-size: 15px;
  margin: 12px 0px 15px 10px;
}
.article-policy {
  background: #f5f5f5;
  border: 1px solid #eee;
  color: #9a9a9a;
  display: block;
  font-size: 10px;
  line-height: 1.8;
  padding: 1em;
  margin-bottom: 10px;
  margin-top: 10px;
}
