.p-feature-bottom {
  margin: 40px auto 60px;
  width: 1046px;
  display: flex;
  justify-content: space-between;

  @include breakPoint(sm) {
    width: fit-content;
    display: block;
    max-width: calc(100% - 40px);
    margin: 0 auto;
  }

  &__banner {
    width: 324px;
    aspect-ratio: 1.78;
    @include breakPoint(sm) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
