.c-post-index-link-btn {
  width: 316px;
  height: 57px;
  border: 1px solid black;
  text-align: center;
  line-height: 57px;
  font-size: 18px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: 500;

  @include breakPoint(sm) {
    width: 200px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  &__arrow-diagonal {
    position: absolute;
    top: 40%;
    right: -20%;
    width: 15px;
    height: 1px;
    background-color: black;
    transform: skewY(35deg);

    @include breakPoint(sm) {
      width: 12px;

      &--osusume {
        right: -25%;
      }
    }
  }

  &__arrow-horizontal {
    position: absolute;
    top: 50%;
    right: -20%;
    width: 110px;
    height: 1px;
    background-color: black;

    @include breakPoint(sm) {
      width: 80px;

      &--osusume {
        right: -25%;
      }
    }
  }
}
