/*========================================
  flexbox
========================================*/

// flex
.c-flex {
  @include flex;
}
// flexWrap
.c-fw-wrap {
  @include flexWrap(wrap);
}
// flexDirection
.c-fd-col {
  @include flexDirection(col);
}
.c-fd-col-sm {
  @include breakPoint(sm) {
    @include flexDirection(col);
  }
}
.c-fd-col-xs {
  @include breakPoint(xs) {
    @include flexDirection(col);
  }
}
// justifyContent
.c-jc-sb {
  @include justifyContent(sb);
}
.c-jc-end {
  @include justifyContent(end);
}
.c-jc-center {
  @include justifyContent(center);
}
// alignItems
.c-ai-center {
  @include alignItems(center);
}
.c-ai-start {
  @include alignItems(start);
}
