.l-header {
  height: 87px;
  padding-top: 20px;
  padding-bottom: 20px;

  @include breakPoint(sm) {
    height: 56px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
