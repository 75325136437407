/*========================================
 sidebar
========================================*/

.l-sidebar {
  display: block;
  background-color: $color_white;
  padding: 15px 0;
  @include breakPoint(sm) {
    padding: 110px 0 50px 0;
  }
  @include breakPoint(xs) {
    padding: 0 0 50px 0;
  }
}
.l-sidebar_product_fixed {
  position: sticky;
  top: 20px;
  right: 0;
  margin-bottom: 30px;
  padding: 10px;
}
