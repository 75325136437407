.p-footer-info-list {
  list-style: none;
  padding-left: 0;
  font-size: 15px;

  @include breakPoint(sm) {
    flex-wrap: wrap;
    line-height: 20px;
    gap: 9px 0;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 14px;
  }

  &__item {
    margin-right: 30px;
    color: white;
    font-family: sans-serif;
    font-family: YuGothic, 'Yu Gothic', sans-serif;
    font-weight: 500;

    @include breakPoint(sm) {
      width: 140px;
      margin-left: 20px;
      margin-right: 0;
    }

    &--last {
      margin-right: 0;
    }
  }
}
