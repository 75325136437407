/*========================================
 utility
========================================*/

/*== other ====================*/
.u-splitLayout {
  @include flex;
  @include justifyContent(sb);
  @include breakPoint(xs) {
    @include flexDirection(col);
  }
  .l-main {
    width: 65%;
    @include breakPoint(sm) {
      width: 60%;
    }
    @include breakPoint(xs) {
      width: 100%;
    }
  }
  .l-sidebar {
    width: 30%;
    @include breakPoint(sm) {
      width: 35%;
    }
    @include breakPoint(xs) {
      width: 100%;
    }
  }
}
.u-ofi {
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;'
}
.u-spOnly {
  display: none;
  @include breakPoint(xs) {
    display: block;
  }
}
.u-pcOnly {
  @include breakPoint(xs) {
    display: none;
  }
}

/*== width ====================*/
.u-w32 {
  width: 32%;
}
.u-w35 {
  width: 35%;
}
.u-w45 {
  width: 45%;
}
.u-w48 {
  width: 48%;
}
.u-w50 {
  width: 50%;
}
.u-w60 {
  width: 60%;
}
.u-w80 {
  width: 80%;
}
.u-w100 {
  width: 100%;
}
.u-w264 {
  width: 264px;
}

// responsive
.u-w70-sm {
  @include breakPoint(sm) {
    width: 70%;
  }
}
.u-w100-xs {
  @include breakPoint(xs) {
    width: 100%;
  }
}

/*== margin ====================*/
// auto
.u-mrla {
  margin-right: auto;
  margin-left: auto;
}
// top
.u-mt12 {
  margin-top: 12px;
}
.u-mt32 {
  margin-top: 32px;
}
// bottom
.u-mb12 {
  margin-bottom: 12px;
}
.u-mb18 {
  margin-bottom: 18px;
}
.u-mb22 {
  margin-bottom: 22px;
}
.u-mb28 {
  margin-bottom: 28px;
}
.u-mb32 {
  margin-bottom: 32px;
}
.u-mb40 {
  margin-bottom: 40px;
}
.u-mb50 {
  margin-bottom: 50px;
}
.u-mb78 {
  margin-bottom: 78px;
}

// responsive
.u-mb78-xs {
  @include breakPoint(xs) {
    margin-bottom: 78px;
  }
}
.u-mb22-xs {
  @include breakPoint(xs) {
    margin-bottom: 22px;
  }
}

/*== text ====================*/
.u-fwb {
  font-weight: bold;
}
.u-fwn {
  font-weight: normal;
}
