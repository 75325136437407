/*========================================
 footer
========================================*/

.l-footer {
  background-color: $color_white;
  .splitContentsWrap {
    border-top: 1px solid $color_gray02;
    border-bottom: 1px solid $color_gray02;
    &__inner {
      @include flex;
      @include flexWrap(wrap);
      @include breakPoint(xs) {
        width: 100%;
      }
    }
  }

  .splitContent {
    padding: 30px;
    border-right: 1px solid $color_gray02;
    @include breakPoint(xs) {
      padding: 20px;
      border-right: none;
    }
    &__logo {
      width: 78px;
      height: auto;
      margin-bottom: 20px;
      &--text {
        font-size: 3rem;
        @include futuraOblique;
        letter-spacing: .3rem;
      }
    }
    &__text {
      margin-bottom: 20px;
      line-height: 1.5;
      color: $color_gray01;
      font-size: 1.3rem;
    }
    &__sns {
      @include flex;
    }
    &__icon {
      width: 22px;
      height: 22px;
      margin-right: 20px;
    }
    &__title {
      margin-bottom: 20px;
      @include futuraOblique;
      font-size: 2rem;
    }
    &__item {
      margin-bottom: 14px;
      font-size: 1.3rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__link {
      transition: .3s;
      &:hover {
        opacity: .5;
        @include breakPoint(md) {
          opacity: 1;
        }
      }
    }
  }

  .splitContent--cuty {
    width: 50%;
    @include breakPoint(xs) {
      width: 100%;
      border-bottom: 1px solid $color_gray02;
    }
  }
  .splitContent--about {
    width: 25%;
    @include breakPoint(xs) {
      width: 50%;
      border-right: 1px solid $color_gray02;
    }
  }
  .splitContent--categories {
    width: 25%;
    @include breakPoint(xs) {
      width: 50%;
    }
  }

  .copyright {
    display: block;
    padding: 10px 0;
    color: $color_gray01;
    font-size: 1.3rem;
    text-align: center;
  }

}
