.p-buttonItem {
  text-decoration: none !important;
  text-align: center;
  border-radius: 5px;
  position: relative;
  font-weight: bold;
  display: block;
  margin: auto auto 40px;

  &.btn_x_big {
    width: 100%;
  }
  &.btn_big {
    width: 350px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &.auto_adjustment {
    width: fit-content;
  }

  &.square::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  &.square {
    display: inline-block;
    position: relative;
    padding: initial;
    min-width: 100px;
  }
  &.square div {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .labelText-speech_bubble {
    position: absolute;
    top: -15px;
    right: 8px;
    left: 8px;
    margin: auto -30px;
    white-space: nowrap;
  }
  .bubble-text-label {
    display: inline-block;
    border-radius: 50px;
    -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.3);
    font-size: 11px;
    letter-spacing: 2px;
    align-self: center;
  }
  .bubble-text-label:after {
    position: absolute;
    background-color: inherit;
    content: '';
    height: 10px;
    width: 10px;
    right: 0;
    left: 0;
    margin: auto;
    bottom: -4px;
    border-bottom: inherit;
    border-left: inherit;
    transform:rotate(-45deg);
    z-index: 0;
  }
  .labelText-quadrangle {
    position: absolute;
    top: -10px;
    right: 8px;
    left: 8px;
    margin: auto -30px;
    white-space: nowrap;
    font-size: 12px;
  }
  .labelText-top_left_quadrangle {
    position: absolute;
    transform: rotate(-14deg);
    top: -9px;
    left: -6px;
    white-space: nowrap;
    font-size: 11px;
  }

  .buttonIcon:before {
    color: inherit;
  }
}
