/*========================================
 header
========================================*/

/*== common ====================*/
// icon
.iconLoupe {
  display: inline-block;
  width: 26px;
  height: auto;
  cursor: pointer;
}

// animation
.animationLine {
  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 2px;
    background: $color_base;
    transform: scale(0, 1);
    transform-origin: center top;
    transition: transform .3s;
    @include breakPoint(md) {
      display: none;
    }
  }
  &:hover::after {
    transform: scale(1, 1);
  }
}

.searchArea {
  display: none;
  width: 100%;
  padding: 20px;
  background-color: $color_gray05;
}

/*== header common ====================*/
.l-header {
  &__inner {
    @include flex;
    @include alignItems(center);
    background-color: $color_white;
    border-bottom: 1px solid $color_gray04;
    .logo {
      height: auto;
      &__img {
        display: block;
        width: 100%;
      }
      &__text {
        font-size: 3rem;
        @include futuraOblique;
        letter-spacing: .3rem;
      }
    }
    .gnav {
      @include breakPoint(sm) {
        display: none;
      }
      &__list {
        @include flex;
        @include alignItems(center);
      }
      &__item {
        position: relative;
        margin-right: 24px;
        &:active {
          opacity: .5;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      &__link {
        display: block;
        font-size: 1.6rem;
        text-decoration: none;
      }
    }
  }
}

/*== header relative ====================*/
.l-header--relative {
  @include breakPoint(sm) {
    display: none;
  }
  .l-header__inner {
    position: relative;
    @include flexDirection(col);
    padding: 20px 60px;
    .icon-wrap {
      position: absolute;
      top: 40px;
      right: 60px;
      &::after {
        bottom: -6px;
      }
    }
    .logo {
      max-width: 200px;
      height: 40px;
      margin-bottom: 30px;
      &__img {
        margin: 0 auto;
      }
      &__text {
        font-size: 4rem;
        @include futuraOblique;
        letter-spacing: .5rem;
      }
    }
    .gnav {
      &__list {
        @include justifyContent(center);
      }
    }
  }
  .searchArea {
    z-index: 10;
    position: absolute;
    top: 143px;
    left: 0;
  }
}

/*== header fixed ====================*/
.l-header--fixed{
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;
  transform: translateY(-100%);
  transition: .5s;
  @include breakPoint(sm) {
    transform: translateY(0%);
  }
  .l-header__inner {
    @include justifyContent(sb);
    padding: 10px 60px;
    @include breakPoint(xs) {
      padding: 6px 15px;
    }
    .logo {
      width: 72px;
      &__text {
        font-size: 3rem;
        @include futuraOblique;
        letter-spacing: .3rem;
      }
    }
    .gnav {
      &__item {
        &::after {
          bottom: -22px;
        }
        &:last-child:after {
          bottom: -16px;
        }
      }
    }
    .sp-items {
      display: none;
      @include breakPoint(sm) {
        display: block;
      }
      .iconLoupe {
        width: 28px;
        height: auto;
        margin-right: 30px;
      }
      .menu-trigger {
        position: relative;
        top: 0;
        right: 0;
        display: inline-block;
        width: 36px;
        height: 26px;
        transition: all .3s;
        span {
          position: absolute;
          left: 0;
          display: inline-block;
          width: 100%;
          height: 2px;
          background-color: $color_base;
          transition: all .3s;
          &:nth-of-type(1) {
            top: 0;
          }
          &:nth-of-type(2) {
            top: 11px;
          }
          &:nth-of-type(3) {
            bottom: 0;
          }
        }
      }
      .menu-trigger--active {
        span {
          &:nth-of-type(1) {
            -webkit-transform: translateY(12px) rotate(-45deg);
            transform: translateY(12px) rotate(-45deg);
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:nth-of-type(3) {
            -webkit-transform: translateY(-12px) rotate(45deg);
            transform: translateY(-12px) rotate(45deg);
          }
        }
      }
    }
  }
}
.slideDown {
  transform: translateY(0%);
}

// drawer
.drawer {
  z-index: 9;
  position: fixed;
  top: 61px;
  left: 0;
  display: none;
  width: 100%;
  height: auto;
  padding-top: 30px;
  background-color: $color_white;
  border-bottom: 1px solid $color_gray02;
  .category {
    &__link {
      position: relative;
      display: block;
      font-size: 1.8rem;
      &::after {
        content: "\f105";
        position: absolute;
        top: 0;
        right: 10px;
        font-family: FontAwesome;
        font-size: 1.8rem;
      }
    }
  }
}
