.p-search-form {
  width: 700px;
  margin: 30px auto 0;
  font-family: serif;

  @include breakPoint(sm) {
    width: 400px;
    max-width: 95%;
  }
}
.p-search-form-pulldown {
  position: relative;

  @include breakPoint(sm) {
    width: 25%;
  }

  &::after {
    content: "";
    position: absolute;
    right: 20px;
    top: 28px;
    width: 8px;
    height: 8px;
    border-top: 2px solid black;
    border-left: 2px solid black;
    transform: translateY(-50%) rotate(-135deg);
    pointer-events: none;

    @include breakPoint(sm) {
      right: 15px;
      top: 24px;
    }
  }
  &__select {
    width: 120px;
    height: 60px;
    padding: 0 30px;
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @include breakPoint(sm) {
      width: 100%;
      height: 50px;
      padding: 0 13px;
      font-size: 14px;
    }
  }
}
.p-search-form-field-and-btn {
  @include breakPoint(sm) {
    width: 100%;
  }
}
.p-search-form-input-wrap {
  padding: 0 8px;

  @include breakPoint(sm) {
    width: calc(100% - 25px);
    padding-right: 0;
  }
}
.p-search-form-input {
  width: 438px;
  height: 60px;
  font-size: 16px;
  padding-left: 20px;

  @include breakPoint(sm) {
    width: 100%;
    height: 50px;
    font-size: 12px;
  }
}
.p-search-form-submit {
  &--pc {
    width: 120px;
    height: 60px;
    background-color: black;
    font-size: 18px;
    line-height: 1.11;
    color: #fff;

    &:hover {
      opacity: 0.8;
    }

    @include breakPoint(sm) {
      display: none;
    }
  }

  &--sp {
    display: none;

    @include breakPoint(sm) {
      display: inline;
      width: 50px;
      height: 50px;
      background-color: black;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50%;
      background-image: url('images/flactive/header_search_icon.png');
    }
  }
}
