.p-header-content {
  height: 44px;
  position: relative;

  @include breakPoint(sm) {
    height: 26px;
  }

  &__logo {
    width: 130px;
    height: 44px;
    margin-left: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('images/flactive/footer_logo.png');

    &--black {
      background-image: url('images/flactive/about_logo.png');
    }

    @include breakPoint(sm) {
      width: 78px;
      height: 26px;
      margin-left: 20px;
    }
  }
  &__menu {
    position: absolute;
    top: 6px;
    right: 40px;

    @include breakPoint(sm) {
      top: 0;
      right: 20px;
    }
  }
  &__search {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('images/flactive/header_search_icon.png');

    &--black {
      background-image: url('images/flactive/header_search_icon_black.png');
    }

    &:hover{
      cursor: pointer;
    }

    @include breakPoint(sm) {
      width: 26px;
      height: 26px;
    }
  }
  &__hamburger {
    width: 49px;
    height: 12px;
    margin-top: 10px;
    margin-left: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('images/flactive/header_menu_icon.png');

    &--black {
      background-image: url('images/flactive/header_menu_icon_black.png');
    }

    &:hover{
      cursor: pointer;
    }

    @include breakPoint(sm) {
      width: 40px;
      height: 12px;
      margin-top: 8px;
      margin-left: 20px;
      background-size: contain;
    }
  }
}
