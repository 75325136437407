.p-feature-top .swiper-slide {
  height: 471px;
  position: relative;

  @include breakPoint(sm) {
    height: 522px;
  }
}
.p-feature-top .swiper-pagination {
  width: fit-content;
  left: auto;
  right: 112px;
  bottom: 30px;

  @include breakPoint(sm) {
    right: 20px;
    bottom: 139px;
  }
}
.p-feature-top .swiper-pagination .swiper-pagination-bullet {
  margin: 0 8px;
}
.p-feature-top .swiper-pagination .swiper-pagination-bullet-active {
  background: white;
}
