.l-top-image {
  height: 768px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('images/flactive/activel-top.png');
  background-position: center;

  @include breakPoint(sm) {
    height: 785px;
    background-position: 59% 50%;
    background-image: url('images/flactive/activel-top-sp.jpg');
  }
}
