/*========================================
 pagination
========================================*/

.c-pagination {
  @include flex;
  @include alignItems(center);
  @include justifyContent(center);
  &__item {
    @include futura;
    font-size: 2rem;
  }
  &__link {
    display: block;
    padding: 6px 10px;
    margin-right: 10px;
    text-align: center;
    .fa {
      &::before {
        color: #707070;
        font-size: 3rem;
      }
    }
  }
  &__link--current {
    border: 1px solid $color_base;
  }
  &__link--more {
    margin-right: 0;
    pointer-events: none;
  }
  &__link--prev {
    margin: 0 30px 0 0;
  }
  &__link--next {
    margin: 0 0 0 30px;
  }
}

.pagination  .nav-links {
  width: 100%;
}
.page-numbers {
  @include flex;
  @include alignItems(center);
  @include justifyContent(center);
  width: 100%;
  /*
  li:nth-child(2) a {
    display: none;
  }
  li:nth-last-child(2) a {
    display: none;
  }
  */
  li {
    @include futura;
    font-size: 2rem;
    display: inline;
  }
  a, span {
    display: block;
    padding: 6px 10px;
    margin-right: 10px;
    text-align: center;
    .fa {
      &::before {
        color: #707070;
        font-size: 3rem;
      }
    }
  }
  span.current {
    border: 1px solid $color_base;
  }
  &__link--more {
    margin-right: 0;
    pointer-events: none;
  }
  &__link--prev {
    margin: 0 30px 0 0;
  }
  &__link--next {
    margin: 0 0 0 30px;
  }
}
