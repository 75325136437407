.c-batsu-wrap {
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: 50px;
  top: 35px;

  @include breakPoint(sm) {
    right: 30px;
    top: 20px;
  }
}
.c-batsu {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;

  &--menu-modal {
    @include breakPoint(sm) {
      width: 20px;
      height: 20px;
    }
  }

  &:hover{
    cursor: pointer;
  }
}

.c-batsu::before, .c-batsu::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 35px;
  background: #333;

  @include breakPoint(sm) {
    width: 2px;
    height: 25px;
  }
}

.c-batsu::before {
  transform: translate(-50%,-50%) rotate(45deg);
}

.c-batsu::after {
  transform: translate(-50%,-50%) rotate(-45deg);
}
