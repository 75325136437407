.l-menu-modal {
  display: none;
  width: 100%;
  height: -webkit-fill-available;
  background-color: white;
  position: fixed;
  z-index: 999;
  top: 0;
  overflow-y: auto;
}
