/*========================================
 featurePage
========================================*/

.p-featurePage {
  padding-top: 30px;
  @include breakPoint(xs) {
    padding-top: 0;
  }
  &__title {
    @include flex;
    @include alignItems(end);
    padding-bottom: 16px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color_base;
    @include futuraOblique;
    font-size: 2.5rem;
    @include breakPoint(xs) {
      font-size: 2.0rem;
    }
  }
  &__banner {
    display: block;
    width: 65%;
    aspect-ratio: 1.78;
    margin: 0 auto;
    @include breakPoint(xs) {
      width: 100%;
    }
  }
  &__description {
    padding: 20px 10px 30px;
    line-height: 1.8;
    font-size: 1.7rem;
    @include breakPoint(xs) {
      padding: 20px 10px;
      line-height: 1.6;
      font-size: 1.5rem;
    }
  }
}
