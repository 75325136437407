.yyi-rinker-contents {
  .yyi-rinker-image {
    width: 55% !important;
    max-width: none;
    @include breakPoint(md) {
      width: 100% !important;
    }
    .yyi-rinker-images-arrow {
      width: 30px;
      height: 30px;
      @include breakPoint(md) {
        width: 25px;
        height: 25px;
      }
      &-right {
        right: -40px !important;
        @include breakPoint(md) {
          right: -10px !important;
        }
      }
      &-left {
        left: -40px !important;
        @include breakPoint(md) {
          left: -10px !important;
        }
      }
    }
    .yyi-rinker-images {
      margin: 0 auto;
      width: 300px;
      height: auto;
      @include breakPoint(md) {
        width: 100%;
        margin: 0px;
        padding: 0 20px;
      }
    }
    a {
      display: block;
      margin: 0 auto;
      width: 300px;
      // height: 230px;

    }
    .yyi-rinker-main-img {
      width: 100% !important;
      margin: 0 auto;
      height: auto !important;
      max-height: none !important;
    }
  }
  .yyi-rinker-info {
    width: 45% !important;
    @include breakPoint(md) {
      width: 100% !important;
      padding-left: 0 !important;
    }
    .credit-box {
      display: none;
    }
    .price-box {
      display: none;
    }
    .yyi-rinker-links {
      li {
        display: block;
        padding: 0;
        width: 100%;
        margin-right: 0 !important;
        a {
          border: 1px solid #F44336;
          color: #F44336;
          background-color: #fff !important;
          box-shadow: none;
          &:after {
            color: #F44336;
          }
        }

        &:before {
          display: none;
        }
      }

    }
  }

  .yyi-rinker-box {
    font-size: 1.6rem;
    border: 1px solid #e0e0e0 !important;
  }
  .yyi-rinker-title {
    font-weight: bold !important;
  }
  .credit-box {
    line-height: 2.2rem;
    color: #999 !important;
    font-size: 1rem !important;
    a {
      color: #999 !important;
    }
  }
  .brand {
    line-height: 2.2rem;
    color: #999 !important;
    font-size: 1.2rem;
    @include breakPoint(md) {
      text-align: left;
    }
  }
  .price-box {
    line-height: 2.4rem;
    font-size: 1.5rem;
    span {
      color: #999 !important;
    }
  }
}
