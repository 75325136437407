.p-sns-list {
  width: 200px;
  height: 30px;
  margin: 31px auto 60px;
  display: flex;
  justify-content: center;

  &__icon-link {
    // margin-right: 30px;
    margin: 0 auto;
    text-align: center;
  }

  &__icon {
    width: 30px;
    height: 30px;
  }
}
