.p-professional-swiper {
  width: 1100px;

  @include breakPoint(sm) {
    width: 656px;
    margin-left: calc(50% - 656px/2) !important;
  }
}

.p-professional-swiper .swiper-slide {
  height: 259px;
  margin-bottom: 60px;
}
