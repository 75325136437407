.p-gallery__ {
  &block {
    display: flex;
    width: 100%;

    @media screen and (max-width: 640px) {
      display: block;
    }
  }

  &singleImg {
    display: block;
    width: initial;
    margin: auto;
  }

  @media screen and (max-width: 640px) {
    &flex{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &exhibitBlock {
    margin: 3px;
    position: relative;

    &::before {
      padding-top: 100%;
    }

    &.bigItem {
      min-width: 40%;
      @media screen and (max-width: 640px) {
        width: calc((100% / 3) * 2 - 6px);
      }
    }

    &.bigItem:before {
      content: "";
      display: block;
    }

    &.miniItem {
      width: calc((100% / 3) - 6px);
      max-height: 100%;
    }

    &.offsetExhibitsMiniItem {
      width: calc((100% / 5) - 6px);
      max-height: 100%;
    }

    &.offsetExhibitsMiniItem:before {
      content: "";
      display: block;
    }

    &.item {
      width: 100%;
      max-height: 100%;
    }

    &.item:before {
      content: "";
      display: block;
    }

    @media screen and (max-width: 640px) {
      &.miniItem {
        width: calc((100% / 3) - 6px);
        padding-top: calc((100% / 3) - 6px);
      }

      &.offsetExhibitsMiniItem {
        width: calc((100% / 2) - 6px);
        max-height: 100%;
      }

      &.sp_miniItem_normal {
        float: left;
      }

      &.sp_miniItem_float_left {
        float: left;
      }

      &.sp_miniItem_float_right {
        float: right;
      }

      &.sp_bigItem_normal { 
        width: calc(((100% / 3) * 2) - 6px);
      }

      &.sp_bigItem_float_left {
        float: left;
      }

      &.sp_bigItem_float_right {
        float: right;
      }

      &.item {
        width: 48%;
      }
    }
  }

  &exhibitWrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #eee;

    &:hover {
      background-color: #000;
    }

    @media screen and (max-width: 640px) {
      background-color: rgba(0, 0, 0, 0);

      &:hover {
        background-color: rgba(0, 0, 0, 1);
      }
    }
  }

  &exhibit {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &:before {
      display: block;
      content: "";
    }

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    @media screen and (max-width: 640px) {
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }

  &exhibit__miniItemBlock {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}
