.c-category-logo-wrap {
  height: 100px;
  border: 1px solid black;
}
.c-category-logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;

  // &__first {
  //   background-image: url('images/category/category_icon_black_01.png');
  // }
  // &__secound {
  //   background-image: url('images/category/category_icon_black_02.png');
  // }
  // &__third {
  //   background-image: url('images/category/category_icon_black_03.png');
  // }
  // &__fourth {
  //   background-image: url('images/category/category_icon_black_04.png');
  // }
  // &__fifth {
  //   background-image: url('images/category/category_icon_black_05.png');
  // }
  // &__sixth {
  //   background-image: url('images/category/category_icon_black_06.png');
  // }
  // &__seventh {
  //   background-image: url('images/category/category_icon_black_07.png');
  // }
  // &__eighth {
  //   background-image: url('images/category/category_icon_black_08.png');
  // }
}
.c-category-name-wrap {
  height: 30px;
  text-align: center;
}
.c-category-name {
  font-size: 16px;
  line-height: 2.5;
  color: #000;
  font-weight: 500;
  font-family: YuGothic, 'Yu Gothic', sans-serif;
}
