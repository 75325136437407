.l-feature {
  height: 971px;

  @include breakPoint(sm) {
    height: 1455px;
    height: inherit;
    min-height: 750px;
    position: relative;
  }
}
