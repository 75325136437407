.p-feature-bottom .swiper-wrapper {
  margin-left: -361px;

  @include breakPoint(sm) {
    margin-top: -239px;
    margin-left: 0;
    z-index: -1;
  }
}
.p-feature-bottom .swiper-slide {
  height: 183px;
  background-repeat: no-repeat;
  background-size: cover;

  &--first {
    background-image: url('images/thumb_02.png');
  }
  &--secound {
    // background-image: url('media/cheerful/eyecatch/02.png');
  }
  &--third {
    // background-image: url('media/cheerful/eyecatch/03.png');
  }
  &--fourth {
    background-image: url('images/thumb_05.png');
  }
}
.p-feature-bottom .swiper-slide-active {
  height: 0px;
}
.p-feature-bottom .swiper-slide-duplicate-active {
  height: 0px;
}
