.popup_gallery {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  opacity: 0;
  visibility: hidden;
  transition: .6s;

  &__inner{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width:70%;
    height: 58%;
    background-color: #fff;
    z-index: 2;
    border-radius: 5px;

    @media screen and (max-width: 768px) {
      width: 90%;
      height: 740px;
    }

    @media screen and (max-width: 640px) {
      width: 90%;
      height: 720px;
    }
  }

  &__exhibit{
    height: 100%;

    @media screen and (max-width: 768px) {
      height: 40%;
    }
    @media screen and (max-width: 640px) {
      height: 40%;
    }

    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }

    .close-btn {
      font-size: 20px;
      color: #333;

      @media screen and (max-width: 768px) {
        width: 30px;
        height: 35px;
        line-height: 35px;
        z-index: 100;
      }
      @media screen and (max-width: 640px) {
        width: 30px;
        height: 35px;
        line-height: 35px;
        z-index: 100;
      }
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      flex-flow: column;
      height: 700px;
    }
    @media screen and (max-width: 640px) {
      flex-flow: column;
      height: 700px;
    }

    .col {
      width: 100%;
      height: 100%;
      .row {
        margin-bottom: 60px;

        @media screen and (max-width: 768px) {
          margin-bottom: 30px;
        }
        @media screen and (max-width: 640px) {
          height: 30%;
        }
      }
    }
  }

  &__left_block {
    @media screen and (max-width: 768px) {
      padding: 0;
      height: 40% !important;
    }
    @media screen and (max-width: 640px) {
      padding: 0;
      height: 40% !important;
    }

    .exhibit_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px 0 0 5px;

      @media screen and (max-width: 768px) {
        border-radius: 5px 5px 0 0;
        height: 95%;
      }
      @media screen and (max-width: 640px) {
        border-radius: 5px 5px 0 0;
      }
    }

    &__image_source_url_block {
      position: relative;
      width: 100%;
      height: 5%;

      .image_source_url {
        position: absolute;
        height: 10px;
        color: gray;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        padding-right: 10px;
      }
    }
  }

  &__right_block {
    margin-top: 40px;
    padding: 0 30px;
    height: calc(100% - 40px) !important;
    overflow: scroll;

    @media screen and (max-width: 1024px) {
      margin-top: 0;
      overflow: scroll;
      height: 435px !important;
    }
    @media screen and (max-width: 640px){
      margin-top: 0;
      overflow: scroll;
      height: 420px !important;
    }

    &__detail {
      height: 95%;
      overflow: scroll;

      .product_info {
        display: flex;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .product_detail {
          margin-top: 20px;
          padding-top: 5px;

          @media screen and (max-width: 768px) {
            margin-top: 0;
          }
          @media screen and (max-width: 640px) {
            margin-top: 0;
          }

          .product_caption {
            font-size: 18px;
            font-weight: 700;
            margin-top: 12px;
          }

          .product_attribute {
            margin-left: 10px;
            margin-top: 12px;
            font-size: 15px;
          }
        }

        .product_link_button {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 10px;

          .p-productLinkBotton {
            width: 230px;
            margin-top: 6px;
            @media screen and (max-width: 640px) {
              width: 260px;
            }
          }
        }

      }
    }

    &__image_source_url_block {
      position: relative;
      width: 100%;
      height: 5%;

      .image_source_url {
        position: absolute;
        height: 10px;
        color: gray;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &__related_post_caption {
    font-size: 20px;
    font-weight: 800;
    border-bottom: 1px solid;
    width: 100%;

    @media screen and (max-width: 1024px) {
      margin-top: 40px;
    }
    @media screen and (max-width: 768px) {
      margin-top: 40px;
    }
    @media screen and (max-width: 640px) {
      margin-top: 40px;
    }
  }

  &__related_post_list {
    margin-top: 25px;

    ul {
      .related_post {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        color: #000000;
        font-size: 15px;

        .eyecatchable_image{
          width: 40px;
          height: 40px;
          margin-right: 30px;
          border-radius: 1px;

          &:hover {
            opacity: .5;
          }
        }

        .related_post_title {
          color: #000000 !important;
        }
      }
    }
  }
}

.popup-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  z-index: 1;
}

.is-show {
  opacity: 1;
  visibility: visible;
}

.row {
   @media screen and (max-width: 768px) {
    margin: 0;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 0;
  }
}
